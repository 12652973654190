import axios from "axios";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? "https://localhost:7118"
    : "https://lingoninjav2.azurewebsites.net";

export async function getSpeechSdkToken() {
  try {
    const res = await axios.post(`${baseUrl}/api/Speech/Token`);
    const token = res.data.token;
    console.log("Get-Speech-SDK's Token: ", token);
    return { token: token, region: "eastus" };
  } catch (error) {
    console.log("Get-Speech-SDK's Error: ", error);
    return null;
  }
}

export async function getTextToSpeechFromServer(
  inputText,
  voiceName,
  language
) {
  try {
    const formData = new FormData();
    formData.append("Text", inputText);
    formData.append("VoiceName", voiceName);
    formData.append("Language", language);

    const res = await axios.post(
      `${baseUrl}/api/Speech/ConvertTextToSpeech`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    const blobURL = res.data.blobUrl;
    return blobURL;
  } catch (error) {
    alert(error);
    console.log("Get-Speech-SDK's Error: ", error);
    return null;
  }
}

export async function getVoiceList() {
  try {
    const res = await axios.get(`${baseUrl}/api/Speech/getVoiceList`);
    const list = res.data;
    console.log("Get-Voice-List's Data: ", list);
    return list;
  } catch (error) {
    alert(error);
    console.log("Get-Voice-List's Error: ", error);
    return null;
  }
}

export async function getAllRecordings() {
  try {
    const res = await axios.get(`${baseUrl}/api/Speech/GetAllRecordings`);
    const list = res.data;
    return list;
  } catch (error) {
    alert(error);
    console.log("Error: ", error);
    return null;
  }
}

export async function getRecordingsByUserId(userId) {
  try {
    const res = await axios.get(
      `${baseUrl}/api/Speech/GetUserRecordings/${userId}`
    );
    const list = res.data;
    return list;
  } catch (error) {
    alert(error);
    console.log("Error: ", error);
    return null;
  }
}
export async function getAllUsers() {
  try {
    const res = await axios.get(`${baseUrl}/api/User/GetAllUsers`);
    const list = res.data;
    return list;
  } catch (error) {
    alert(error);
    console.log("Error: ", error);
    return null;
  }
}
