import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "../index.css";
import axios from "axios";
import ReactGA from "react-ga4";
import lingoNinjaLogo from "../images/logo.png";
import NavigationBar from "../components/NavigationBar.jsx";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../services/AuthService.jsx";
import { Helmet } from "react-helmet-async";

export default function CreateUser() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEnabled, setIsEnabled] = useState(true); // Default to true
  const [role, setRole] = useState("");

  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { authData } = useAuth();

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "https://localhost:7118"
      : "https://lingoninjav2.azurewebsites.net";

  function handleNameChange(e) {
    setName(e.target.value);
  }

  function handleEmailChange(e) {
    setEmail(e.target.value);
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  function handleIsEnabledChange(e) {
    setIsEnabled(e.target.value === "true");
  }

  function handleRoleChange(e) {
    setRole(e.target.value);
  }

  async function handleCreateUserSubmit(e) {
    e.preventDefault();
    setError("");
    try {
      const createUserUrl = `${baseUrl}/api/User/CreateUser`;
      const params = {
        Name: name,
        Email: email,
        Password: password,
        IsEnabled: isEnabled === true,
        Role: role,
      };

      const response = await axios.post(createUserUrl, null, { params });
      console.log(response.data);
      if (response.data) {
        navigate("/users");
      }
    } catch (err) {
      console.log(err.message);
      console.log(err);
      if (err.response.data) {
        setError(err.response.data);
      } else {
        setError("There is an issue with backend.");
      }
    }
  }

  function navigateToHome() {
    navigate("/");
  }

  useEffect(() => {
    // Check if user is not Admin, then redirect
    if (authData.role !== "Admin") {
      navigate("/speech"); // Redirect to the home page or any other page
    }
    //Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [authData, navigate]);

  return (
    <Container className="create-user-container">
      <Helmet>
        <title>Create User | Lingo Ninja</title>
        <meta name="description" content="Setup account in Lingo Ninja" />
        <link rel="canonical" href="/SignIn" />
      </Helmet>
      <NavigationBar />
      <div className="form-signin w-100 m-auto text-center">
        <form onSubmit={handleCreateUserSubmit}>
          <div className="card">
            <div className="card-body shadow">
              <img
                className="mb-4"
                onClick={navigateToHome}
                src={lingoNinjaLogo}
                alt=""
              />
              <h3>Create User</h3>
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingName"
                  value={name}
                  onChange={handleNameChange}
                  placeholder="Enter your name"
                />
                <label htmlFor="floatingName">Name</label>
              </div>
              <div className="form-floating mt-3">
                <input
                  type="email"
                  className="form-control"
                  id="floatingEmail"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="name@example.com"
                />
                <label htmlFor="floatingEmail">Email address</label>
              </div>
              <div className="form-floating mt-3">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Password"
                />
                <label htmlFor="floatingPassword">Password</label>
              </div>
              <div className="form-floating mt-3">
                <select
                  className="form-select"
                  id="floatingIsEnabled"
                  value={isEnabled ? "true" : "false"}
                  onChange={handleIsEnabledChange}
                >
                  <option value="true">Enabled</option>
                  <option value="false">Disabled</option>
                </select>
                <label htmlFor="floatingIsEnabled">Is Enabled?</label>
              </div>
              
              <div className="form-floating mt-3">
                <select
                  className="form-select"
                  id="floatingRole"
                  onChange={handleRoleChange}
                  value={role}
                  placeholder="Enter user role"
                >
                  <option value="Admin">Admin</option>
                  <option value="User">User</option>
                </select>
                <label htmlFor="floatingRole">Role</label>
              </div>
              <button className="btn btn-primary w-100 mt-3 py-2" type="submit">
                Create User
              </button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
}
