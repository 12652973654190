import * as speechSDK from "microsoft-cognitiveservices-speech-sdk";
import { getSpeechSdkToken } from "./LingoService";

export async function textToSpeech(textToSpeak, voiceName) {
  const token = await getSpeechSdkToken();

  const speechConfig = speechSDK.SpeechConfig.fromAuthorizationToken(
    token.authToken,
    token.region
  );

  var speechConfig2 = speechSDK.SpeechConfig.fromSubscription(
    "158d8cd70c03430a99500f878b4b3552",
    "eastus"
  );
  speechConfig2.speechSynthesisVoiceName = voiceName;

  let player = new speechSDK.SpeakerAudioDestination();

  let audioConfig = speechSDK.AudioConfig.fromSpeakerOutput(player);

  let synthesizer = new speechSDK.SpeechSynthesizer(speechConfig2, audioConfig);

  synthesizer.speakTextAsync(
    textToSpeak,
    (result) => {
      let status;
      if (result.reason === speechSDK.ResultReason.SynthesizingAudioCompleted) {
        status = `Synthesis finished for "${textToSpeak}".\n`;
      } else if (result.reason === speechSDK.ResultReason.Canceled) {
        status = `Synthesis failed. Error detail: ${result.errorDetails}.\n`;
      }
      synthesizer.close();
      synthesizer = undefined;
    },
    function (error) {
      synthesizer.close();
      synthesizer = undefined;
    }
  );
}

export async function textToSpeechWithStyle(
  textToSpeak,
  voiceName,
  language,
  style
) {
  const token = await getSpeechSdkToken();

  const speechConfig = speechSDK.SpeechConfig.fromAuthorizationToken(
    token.authToken,
    token.region
  );

  var speechConfig2 = speechSDK.SpeechConfig.fromSubscription(
    "158d8cd70c03430a99500f878b4b3552",
    "eastus"
  );
  speechConfig2.speechSynthesisVoiceName = voiceName;

  let player = new speechSDK.SpeakerAudioDestination();

  let audioConfig = speechSDK.AudioConfig.fromSpeakerOutput(player);

  let synthesizer = new speechSDK.SpeechSynthesizer(speechConfig2, audioConfig);
  let conversionXML =
    '<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang="' +
    language +
    '">' +
    '<voice name ="' +
    voiceName +
    '">' +
    '<mstts:express-as style="' +
    style +
    '">' +
    textToSpeak +
    "</mstts:express-as>" +
    "</voice>" +
    "</speak>";

  synthesizer.speakSsmlAsync(
    conversionXML,
    (result) => {
      let status;
      if (result.reason === speechSDK.ResultReason.SynthesizingAudioCompleted) {
        status = `Synthesis finished for "${textToSpeak}".\n`;
      } else if (result.reason === speechSDK.ResultReason.Canceled) {
        status = `Synthesis failed. Error detail: ${result.errorDetails}.\n`;
      }
      synthesizer.close();
      synthesizer = undefined;
    },
    function (error) {
      alert(error);
      synthesizer.close();
      synthesizer = undefined;
    }
  );
}
