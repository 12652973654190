import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import lingoNinjaLogo from "../images/logo.png";
import { useAuth } from "../services/AuthService.jsx";
import { useNavigate } from "react-router-dom";

const NavigationBar = () => {
  const { authData } = useAuth();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    // Call the logout function from the authentication context
    logout();
    navigate("/");
  };

  function navigateToHome() {
    navigate("/");
  }

  return (
    <nav className="navbar bg-body-tertiary">
      <div className="container-fluid">
        <a className="navbar-brand" href="javascript:void(0);" onClick={navigateToHome}>
          <img src={lingoNinjaLogo} alt="" />
        </a>
        {authData ? (
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {authData.name}
            </button>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownMenuButton"
            >
              {authData.role === "Admin" ? (
                <>
                  <li>
                    <a className="dropdown-item" href="/speech">
                      <FontAwesomeIcon icon={faGear} className="me-2" />
                      Speech
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/users">
                      <FontAwesomeIcon icon={faGear} className="me-2" />
                      Users
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/createUser">
                      <FontAwesomeIcon icon={faGear} className="me-2" />
                      Create User
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/settings">
                      <FontAwesomeIcon icon={faGear} className="me-2" />
                      Settings
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/contactUs">
                      <FontAwesomeIcon icon={faGear} className="me-2" />
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={handleLogout}
                    >
                      <FontAwesomeIcon icon={faGear} className="me-2" />
                      Logout
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <a className="dropdown-item" href="/speech">
                      <FontAwesomeIcon icon={faGear} className="me-2" />
                      Speech
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/settings">
                      <FontAwesomeIcon icon={faGear} className="me-2" />
                      Settings
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/contactUs">
                      <FontAwesomeIcon icon={faGear} className="me-2" />
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={handleLogout}
                    >
                      <FontAwesomeIcon icon={faGear} className="me-2" />
                      Logout
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>
        ) : (
          <a className="btn btn-secondary" href="/login">Login</a>
        )}
      </div>
    </nav>
  );
};

export default NavigationBar;
