import React, { useEffect, useState } from "react";
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "../index.css";
import axios from "axios";
import { getAllUsers } from "../services/LingoService.jsx";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import NavigationBar from "../components/NavigationBar.jsx";
import { Helmet } from "react-helmet-async";
import moment from 'moment';
import { useAuth } from "../services/AuthService.jsx";
import { useNavigate } from "react-router-dom";

export default function Users() {
  const [allUsers, setAllUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { authData } = useAuth();

  const columnDefs = [
    { headerName: "Email", field: "email", flex: 1, filter: true },
    { headerName: "Name", field: "name", filter: true },
    { headerName: "Role", field: "role", filter: true },
    { headerName: "Status", field: "status", filter: true },
    { headerName: "Last Login", field: "lastLogin", filter: true },
    {
      headerName: "Requests",
      field: "requests",
      filter: true,
      cellRenderer: renderRequests,
    },
    {
      headerName: "Details",
      field: "details",
      filter: true,
      cellRenderer: renderDetails,
    },
    {
      headerName: "Delete",
      field: "delete",
      filter: true,
      cellRenderer: renderDelete,
    },
  ];

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "https://localhost:7118"
      : "https://lingoninjav2.azurewebsites.net";

  function handleUpdateUser(user) {
    setSelectedUser(user);
    setShowModal(true);
  }

  const handleDeleteUser = async (user) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the user with email: ${user.email}?`
    );
    if (!confirmDelete) {
      return;
    }
    try {
      const deleteUserUrl = `${baseUrl}/api/User/DeleteUserByEmail`;
      const params = { email: user.email };

      const response = await axios.delete(deleteUserUrl, { params });

      if (response.status === 200) {
        setAllUsers((prevUsers) =>
          prevUsers.filter((u) => u.email !== user.email)
        );
      } else {
        setError("Failed to delete user.");
      }
    } catch (error) {
      console.error("Error deleting user: ", error);
      setError("Error deleting user.");
    }
  }

  // Custom cell renderer functions
  function renderRequests(params) {
    return <span className="badge text-bg-secondary">{params.value}</span>;
  }

  function renderDetails(params) {
    return (
      <FontAwesomeIcon
        className="thumb-pointer"
        icon={faEye}
        onClick={() => handleUpdateUser(params.data)}
      />
    );
  }

  function renderDelete(params) {
    return (
      <FontAwesomeIcon
        className="thumb-pointer"
        icon={faTrash}
        onClick={() => handleDeleteUser(params.data)}
      />
    );
  }

  const handleSubmitUpdateUser = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    try {
      // Get the updated values from the form fields

      // Perform the API call to update the user
      const updateUserUrl = `${baseUrl}/api/User/UpdateUser`;
      const { name, email, status, role } = selectedUser;
      const params = {
        Name: name,
        Email: email,
        IsEnabled: status === "true",
        Role: role,
      };

      const response = await axios.post(updateUserUrl, null, { params });

      // Update the user in the local state
      setAllUsers((prevUsers) =>
        prevUsers.map((user) => {
          if (user.email === selectedUser.email) {
            return { ...user, name, status: params.IsEnabled };
          }
          return user;
        })
      );

      // Close the modal after successful submission
      setShowModal(false);
    } catch (error) {
      setError("Error in updating user.");
      console.error("Error updating user: ", error);
      // Handle error, display error message, etc.
    }
  };

  useEffect(() => {
    //Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    if (authData.role !== 'Admin') {
      navigate(-1); // Navigate to the previous page
    }

    const fetchData = async () => {
      try {
        const res = await getAllUsers();
        const transformedData = res.map((item) => ({
          email: item.email,
          name: item.name,
          role: item.role,
          status: item.isEnabled ? true : false,
          lastLogin: moment(item.lastLogin).format("DD-MM-YYYY h:mm A"),
          requests: item.requestCount,
        }));
        setAllUsers(transformedData);
        console.log(res);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData(); // Fetch data when component mounts
  }, []); // Empty dependency array ensures useEffect runs only once on mount

  return (
    <Container className="users-app-container">
      <Helmet>
        <title>Users | LingoNinja</title>
        <meta name="description" content="Manage Lingo Ninja's Users" />
        <link rel="canonical" href="/Users" />
      </Helmet>
      <NavigationBar />
      <div className="row mt-3">
        <div className="col-12 users-table-container">
          <div
            className="ag-theme-quartz"
            style={{ width: "100%", height: "100%" }}
          >
            <AgGridReact
              columnDefs={columnDefs}
              rowData={allUsers}
              defaultColDef={{ filter: true }}
              domLayout="autoHeight"
              suppressCellSelection={true}
              enableCellTextSelection={true}
            />
          </div>
        </div>
      </div>
      {/* Modal */}
      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          Update User
        </ModalHeader>
        <ModalBody>
          <h2 className="text-center">User Details</h2>
          {error && error.length > 0 (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <Form onSubmit={handleSubmitUpdateUser}>
            <FormGroup>
              <label htmlFor="status" className="mb-2">
                User Status
              </label>
              <select
                className="form-select"
                id="status"
                onChange={(e) =>
                  setSelectedUser((prevState) => ({
                    ...prevState,
                    status: e.target.value,
                  }))
                }
                value={selectedUser ? selectedUser.status.toString() : ""}
              >
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </FormGroup>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="name"
                name="name"
                id="name"
                value={selectedUser?.name}
                onChange={(e) =>
                  setSelectedUser((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={selectedUser?.email}
                disabled
                onChange={(e) =>
                  setSelectedUser((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }))
                }
              />
            </FormGroup>
            <button type="submit" className="btn btn-primary w-100">
              Update User
            </button>
          </Form>
        </ModalBody>
      </Modal>
    </Container>
  );
}
