import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "../index.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../services/AuthService";
import ReactGA from "react-ga4";
import lingoNinjaLogo from "../images/logo.png";
import { Helmet } from "react-helmet-async";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { login } = useAuth();

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? "https://localhost:7118"
      : "https://lingoninjav2.azurewebsites.net";

  const navigate = useNavigate();

  function handleEmailChange(e) {
    setEmail(e.target.value);
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  async function handleLoginSubmit(e) {
    e.preventDefault();
    setError("");
    try {
      const response = await axios.get(`${baseUrl}/api/Auth/Login`, {
        params: {
          Email: email,
          Password: password,
        },
        headers: {
          accept: "*/*",
        },
      });
      console.log(response.data);
      if (!response.data.isEnabled) {
        setError("Your account is disabled. Please contact support.");
        return; // Stop the process if the user is disabled
      }
      login(response.data);
      if (response.data.role === "Admin") {
        navigate("/users");
      } else {
        navigate("/speech");
      }
    } catch (err) {
      console.log(err.message);
      console.log(err);
      if (err.response.data) {
        setError(err.response.data);
      } else {
        setError("User is not registered in the system.");
      }
    }
  }

  function navigateToHome() {
    navigate("/");
  }

  useEffect(() => {
    //Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  });

  return (
    <Container className="login-container">
      <Helmet>
        <title>Login | LingoNinja</title>
        <meta name="description" content="Login to Lingo Ninja" />
        <link rel="canonical" href="/Login" />
      </Helmet>
      <div className="form-signin w-100 m-auto text-center">
        <form onSubmit={handleLoginSubmit}>
          <div className="card">
            <div className="card-body shadow">
              <img
                className="mb-4"
                onClick={navigateToHome}
                src={lingoNinjaLogo}
                alt=""
              />
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <div className="form-floating">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  value={email}
                  onKeyUp={handleEmailChange}
                  onChange={handleEmailChange}
                  placeholder="name@example.com"
                />
                <label htmlFor="floatingInput">Email address</label>
              </div>
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  value={password}
                  onKeyUp={handlePasswordChange}
                  onChange={handlePasswordChange}
                  placeholder="Password"
                />
                <label htmlFor="floatingPassword">Password</label>
              </div>
              <button className="btn btn-primary w-100 py-2" type="submit">
                Sign in
              </button>
              <span className="p-2">v1.12</span>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
}
