import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import Select from "react-select";
import ReactGA from "react-ga4";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.css";
import "../index.css";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

import {
  getTextToSpeechFromServer,
  getRecordingsByUserId,
} from "../services/LingoService.jsx";
import { textToSpeech } from "../services/SpeechService.jsx";
import { voiceList } from "../services/VoiceList.jsx";
import NavigationBar from "../components/NavigationBar.jsx";
import { useAuth } from "../services/AuthService.jsx";

ReactGA.initialize("G-YKE3LME8BW");

export default function App() {
  const [inputText, setInputText] = useState("");
  const [users, setUsers] = useState([]);
  const [styleList, setStyleList] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [user, setUser] = useState("");
  const [activeUser, setActiveUser] = useState(null);
  const [recordings, setRecordings] = useState([]);

  const { authData, updateAuthData } = useAuth();
  const [userId, setUserId] = useState(authData.userId);

  console.log(authData);

  const defaultLanguageOption = {
    val: "English (United States)",
    label: "English (United States)",
  };
  const [language, setLanguage] = useState(defaultLanguageOption);

  const columnDefs = [
    { headerName: "Date", field: "date", filter: true },
    { headerName: "Language", field: "language", filter: true },
    { headerName: "Voice", field: "voice", filter: true },
    {
      headerName: "Download Link",
      field: "downloadLink",
      filter: true,
      flex: 1,
      cellRenderer: renderDownloadLink,
    },
    {
      headerName: "Text",
      field: "text",
      filter: true,
      flex: 1,
      cellRenderer: renderText,
    },
    { headerName: "Character", field: "character", filter: true },
  ];

  function renderText(params) {
    return <span dangerouslySetInnerHTML={{ __html: params.value }} />;
  }

  function renderDownloadLink(params) {
    return <span dangerouslySetInnerHTML={{ __html: params.value }} />;
  }

  function formatDate(isoString) {
    const date = new Date(isoString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const fetchRecordings = async () => {
    const data = await getRecordingsByUserId(userId);
    if (data) {
      var arrayToOutput = [];
      data.forEach((element) => {
        var obj = {};
        obj.date = formatDate(element.date);
        obj.language = element.language;
        obj.voice = element.voiceName;
        obj.downloadLink = `<a href="${element.blobUrl}" target="_blank" class="btn btn-primary btn-sm">Download Voice</a>`;
        obj.text = element.text;
        obj.character = element.character;
        arrayToOutput.push(obj);
      });
      setRecordings(arrayToOutput.reverse());
    } else {
      console.log("Failed to fetch recordings");
    }
  };

  useEffect(() => {
    fetchRecordings();

    //Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    const localeNameSet = new Set();

    voiceList.forEach((voice) => {
      localeNameSet.add(voice.LocaleName);
    });

    const languages = Array.from(localeNameSet);
    const options = languages.map((element) => ({
      value: element,
      label: element,
    }));

    setLanguageOptions(options); // Set the language options in state
    handleChangeLanguage(defaultLanguageOption);
  }, []);

  const getWordCount = (text) => {
    return text.trim() === "" ? 0 : text.trim().split(/\s+/).length;
  };

  const getCharacterCount = (text) => {
    return text.length;
  };

  function getEntriesByLocaleName(voiceList, localeName) {
    return voiceList.filter((voice) => voice.LocaleName === localeName);
  }

  function handleChange(e) {
    setInputText(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    await textToSpeech(inputText, user);
    // await textToSpeechWithStyle(inputText, "en-US-JennyNeural", "en-US", "sad");
  }

  const handleChangeLanguage = (selectedOption) => {
    setLanguage(selectedOption);
    var results = getEntriesByLocaleName(
      voiceList,
      selectedOption.value ? selectedOption.value : selectedOption.val
    );
    var outputResults = [];
    results.forEach((item) => {
      var obj = {};
      obj.name = item.DisplayName;
      obj.sex = item.Gender;
      obj.language = item.LocaleName;
      obj.description = item.Description
        ? item.Description
        : "AI generated voice";
      obj.shortName = item.ShortName;
      outputResults.push(obj);
    });
    setUsers(outputResults);
  };

  function getEntriesByShortName(voiceList, shortName) {
    return voiceList.filter((voice) => voice.ShortName === shortName);
  }

  function handleUserSelection(e) {
    e.preventDefault();

    var shortName = e.target.getAttribute("data-short-name");
    setUser(shortName);
    setActiveUser(shortName);
    var selectedEntries = getEntriesByShortName(voiceList, shortName);
    var selectedStyleList = [];
    selectedEntries.forEach((element) => {
      if (element.StyleList && element.StyleList.length > 0) {
        element.StyleList.forEach((element) => {
          var obj = {};
          obj.name = element;
          selectedStyleList.push(obj);
        });
      }
      setStyleList(selectedStyleList);
    });
  }

  async function handleGenerateVoice(e) {
    e.preventDefault();
    var blobURL = await getTextToSpeechFromServer(
      inputText,
      user,
      language.val ? language.val : language.value
    );
    console.log(blobURL);
    fetchRecordings();
  }

  return (
    <Container className="app-container">
      <NavigationBar />

      <div className="row mt-3">
        <div className="col-sm-6 col-12 type-text-container">
          <div className="card">
            <div className="card-header text-center pt-3 pb-3">
              <h4 className="mb-0">Type or Paste your Text Below</h4>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <textarea
                  className="form-control"
                  value={inputText}
                  onChange={handleChange}
                  onKeyUp={handleChange}
                ></textarea>
              </form>
              <div className="row chars-words-count mt-3">
                <div className="col-sm-4 col-6"></div>
                <div className="col-sm-4 col-6 text-center">
                  Character Count: {getCharacterCount(inputText)}
                </div>
                <div className="col-sm-4 col-6 text-end">
                  Words Count: {getWordCount(inputText)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-12 configuration-container">
          <div className="card">
            <div className="card-header text-center pt-3 pb-3">
              <h4 className="mb-0">Configuration</h4>
            </div>
            <div className="card-body">
              <div className="mb-3">
                <h5>Languages</h5>
                <Select
                  id="language-select"
                  value={language}
                  onChange={handleChangeLanguage}
                  options={languageOptions}
                  defaultValue={defaultLanguageOption}
                />
              </div>
              <h5>Users</h5>
              <div className="users-container">
                {users.length > 0 ? (
                  users.map((item, index) => (
                    <div
                      key={index}
                      className={`single-user p-3 mb-3 card-custom ${
                        activeUser === item.shortName ? "active" : ""
                      }`}
                    >
                      {item.sex === "Male" ? (
                        <div className="user-picture male"></div>
                      ) : (
                        <div className="user-picture female"></div>
                      )}
                      <div className="user-bio ms-3">
                        <p className="user-name">{item.name}</p>
                        <br />
                        <p className="user-sex">{item.sex}</p>
                      </div>
                      <p className="user-description">{item.description}</p>
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm w-100"
                            data-short-name={item.shortName}
                            onClick={handleUserSelection}
                          >
                            Select User
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="alert alert-warning w-100" role="alert">
                    No users found.
                  </div>
                )}
              </div>
              {/* <div className="row speaking-styles-row">
                <div className="col-12">
                  <h4 className="mt-3">Speaking Styles</h4>
                  {styleList.length > 0 ? (
                    styleList.map((item, index) => (
                      <button
                        type="button"
                        className="btn btn-secondary speaking-style-btn ms-2 me-2 mb-2"
                      >
                        {item.name}
                      </button>
                    ))
                  ) : (
                    <div className="alert alert-warning" role="alert">
                      No styles found.
                    </div>
                  )}
                </div>
              </div> */}
              <div className="row">
                <div className="col-sm-6 col-12 mt-3">
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={handleSubmit}
                  >
                    Preview Voice Over
                  </button>
                </div>
                <div className="col-sm-6 col-12 mt-3">
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={handleGenerateVoice}
                  >
                    Generate Voice Over
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <div className="card">
            <div className="card-header pt-3 pb-2">
              <h4>Generated Voice Overs</h4>
            </div>
            <div
              className="ag-theme-quartz"
              style={{ height: "500px", width: "100%" }}
            >
              <AgGridReact
                columnDefs={columnDefs}
                rowData={recordings}
                defaultColDef={{ filter: true }}
                suppressCellSelection={true}
                enableCellTextSelection={true}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
