import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const storedAuthData = localStorage.getItem('authData');
    if (storedAuthData) {
      const parsedAuthData = JSON.parse(storedAuthData);
      setAuthData(parsedAuthData);
      axios.defaults.headers.common['Authorization'] = `Bearer ${parsedAuthData.token}`;
    }
    setLoading(false); // Set loading to false after initialization
  }, []);

  const login = (data) => {
    setAuthData(data);
    localStorage.setItem('authData', JSON.stringify(data));
    axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
  };

  const logout = () => {
    setAuthData(null);
    localStorage.removeItem('authData');
    delete axios.defaults.headers.common['Authorization'];
  };

  const updateAuthData = (newData) => {
    const updatedAuthData = { ...authData, ...newData };
    setAuthData(updatedAuthData);
    localStorage.setItem('authData', JSON.stringify(updatedAuthData));
  };

  return (
    <AuthContext.Provider value={{ authData, login, logout, updateAuthData, loading }}>
      {children}
    </AuthContext.Provider>
  );
};