import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "../index.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../services/AuthService";
import ReactGA from "react-ga4";
import lingoNinjaLogo from "../images/logo.png";
import { Helmet } from "react-helmet-async";

export default function NotFound() {
  const { authData } = useAuth();
  const navigate = useNavigate();

  function handleNavigateBack() {
    if (authData && authData.role === "Admin") {
      if (authData.role === "Admin") {
        navigate("/users");
      } else {
        navigate("/speech");
      }
    } else {
      navigate("/");
    }
  }

  useEffect(() => {
    //Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  });

  return (
    <Container className="login-container not-found-container">
      <Helmet>
        <title>Not Found | LingoNinja</title>
        <meta name="description" content="Not Page Found" />
        <link rel="canonical" href="/NotFound" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="form-signin w-100 m-auto text-center">
        <form action="">
          <img
            className="mb-4"
            onClick={handleNavigateBack}
            src={lingoNinjaLogo}
            alt=""
          />
          <h3>404 Page not found!</h3>
          <h6>The page you're looking for doesn't exist.</h6>
        </form>
      </div>
    </Container>
  );
}
