export const voiceList = [
  {
    Name: "Microsoft Server Speech Text to Speech Voice (af-ZA, AdriNeural)",
    DisplayName: "Adri",
    LocalName: "Adri",
    ShortName: "af-ZA-AdriNeural",
    Gender: "Female",
    Locale: "af-ZA",
    LocaleName: "Afrikaans (South Africa)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "147",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (af-ZA, WillemNeural)",
    DisplayName: "Willem",
    LocalName: "Willem",
    ShortName: "af-ZA-WillemNeural",
    Gender: "Male",
    Locale: "af-ZA",
    LocaleName: "Afrikaans (South Africa)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "155",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (am-ET, MekdesNeural)",
    DisplayName: "Mekdes",
    LocalName: "መቅደስ",
    ShortName: "am-ET-MekdesNeural",
    Gender: "Female",
    Locale: "am-ET",
    LocaleName: "Amharic (Ethiopia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "117",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (am-ET, AmehaNeural)",
    DisplayName: "Ameha",
    LocalName: "አምሀ",
    ShortName: "am-ET-AmehaNeural",
    Gender: "Male",
    Locale: "am-ET",
    LocaleName: "Amharic (Ethiopia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "112",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-AE, FatimaNeural)",
    DisplayName: "Fatima",
    LocalName: "فاطمة",
    ShortName: "ar-AE-FatimaNeural",
    Gender: "Female",
    Locale: "ar-AE",
    LocaleName: "Arabic (United Arab Emirates)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "110",
    Description:
      "A clear- sounding voice with great versatility that can adapt to any use case and speak in a way that's easy to understand.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-AE, HamdanNeural)",
    DisplayName: "Hamdan",
    LocalName: "حمدان",
    ShortName: "ar-AE-HamdanNeural",
    Gender: "Male",
    Locale: "ar-AE",
    LocaleName: "Arabic (United Arab Emirates)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "115",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-BH, LailaNeural)",
    DisplayName: "Laila",
    LocalName: "ليلى",
    ShortName: "ar-BH-LailaNeural",
    Gender: "Female",
    Locale: "ar-BH",
    LocaleName: "Arabic (Bahrain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "108",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-BH, AliNeural)",
    DisplayName: "Ali",
    LocalName: "علي",
    ShortName: "ar-BH-AliNeural",
    Gender: "Male",
    Locale: "ar-BH",
    LocaleName: "Arabic (Bahrain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "121",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-DZ, AminaNeural)",
    DisplayName: "Amina",
    LocalName: "أمينة",
    ShortName: "ar-DZ-AminaNeural",
    Gender: "Female",
    Locale: "ar-DZ",
    LocaleName: "Arabic (Algeria)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "110",
    Description:
      "A clear- sounding voice with great versatility that can adapt to any use case and speak in a way that's easy to understand.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-DZ, IsmaelNeural)",
    DisplayName: "Ismael",
    LocalName: "إسماعيل",
    ShortName: "ar-DZ-IsmaelNeural",
    Gender: "Male",
    Locale: "ar-DZ",
    LocaleName: "Arabic (Algeria)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "115",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-EG, SalmaNeural)",
    DisplayName: "Salma",
    LocalName: "سلمى",
    ShortName: "ar-EG-SalmaNeural",
    Gender: "Female",
    Locale: "ar-EG",
    LocaleName: "Arabic (Egypt)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "103",
    Description:
      "A clear- sounding voice with great versatility that can adapt to any use case and speak in a way that's easy to understand.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-EG, ShakirNeural)",
    DisplayName: "Shakir",
    LocalName: "شاكر",
    ShortName: "ar-EG-ShakirNeural",
    Gender: "Male",
    Locale: "ar-EG",
    LocaleName: "Arabic (Egypt)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "115",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-IQ, RanaNeural)",
    DisplayName: "Rana",
    LocalName: "رنا",
    ShortName: "ar-IQ-RanaNeural",
    Gender: "Female",
    Locale: "ar-IQ",
    LocaleName: "Arabic (Iraq)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "98",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-IQ, BasselNeural)",
    DisplayName: "Bassel",
    LocalName: "باسل",
    ShortName: "ar-IQ-BasselNeural",
    Gender: "Male",
    Locale: "ar-IQ",
    LocaleName: "Arabic (Iraq)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "113",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-JO, SanaNeural)",
    DisplayName: "Sana",
    LocalName: "سناء",
    ShortName: "ar-JO-SanaNeural",
    Gender: "Female",
    Locale: "ar-JO",
    LocaleName: "Arabic (Jordan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "98",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-JO, TaimNeural)",
    DisplayName: "Taim",
    LocalName: "تيم",
    ShortName: "ar-JO-TaimNeural",
    Gender: "Male",
    Locale: "ar-JO",
    LocaleName: "Arabic (Jordan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "113",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-KW, NouraNeural)",
    DisplayName: "Noura",
    LocalName: "نورا",
    ShortName: "ar-KW-NouraNeural",
    Gender: "Female",
    Locale: "ar-KW",
    LocaleName: "Arabic (Kuwait)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "121",
    Description:
      "A clear- sounding voice with great versatility that can adapt to any use case and speak in a way that's easy to understand.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-KW, FahedNeural)",
    DisplayName: "Fahed",
    LocalName: "فهد",
    ShortName: "ar-KW-FahedNeural",
    Gender: "Male",
    Locale: "ar-KW",
    LocaleName: "Arabic (Kuwait)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "128",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-LB, LaylaNeural)",
    DisplayName: "Layla",
    LocalName: "ليلى",
    ShortName: "ar-LB-LaylaNeural",
    Gender: "Female",
    Locale: "ar-LB",
    LocaleName: "Arabic (Lebanon)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "99",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-LB, RamiNeural)",
    DisplayName: "Rami",
    LocalName: "رامي",
    ShortName: "ar-LB-RamiNeural",
    Gender: "Male",
    Locale: "ar-LB",
    LocaleName: "Arabic (Lebanon)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "101",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-LY, ImanNeural)",
    DisplayName: "Iman",
    LocalName: "إيمان",
    ShortName: "ar-LY-ImanNeural",
    Gender: "Female",
    Locale: "ar-LY",
    LocaleName: "Arabic (Libya)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "108",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-LY, OmarNeural)",
    DisplayName: "Omar",
    LocalName: "أحمد",
    ShortName: "ar-LY-OmarNeural",
    Gender: "Male",
    Locale: "ar-LY",
    LocaleName: "Arabic (Libya)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "111",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-MA, MounaNeural)",
    DisplayName: "Mouna",
    LocalName: "منى",
    ShortName: "ar-MA-MounaNeural",
    Gender: "Female",
    Locale: "ar-MA",
    LocaleName: "Arabic (Morocco)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "121",
    Description:
      "A clear- sounding voice with great versatility that can adapt to any use case and speak in a way that's easy to understand.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-MA, JamalNeural)",
    DisplayName: "Jamal",
    LocalName: "جمال",
    ShortName: "ar-MA-JamalNeural",
    Gender: "Male",
    Locale: "ar-MA",
    LocaleName: "Arabic (Morocco)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "128",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-OM, AyshaNeural)",
    DisplayName: "Aysha",
    LocalName: "عائشة",
    ShortName: "ar-OM-AyshaNeural",
    Gender: "Female",
    Locale: "ar-OM",
    LocaleName: "Arabic (Oman)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "118",
    Description: "A young voice with a wide range of expressions",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-OM, AbdullahNeural)",
    DisplayName: "Abdullah",
    LocalName: "عبدالله",
    ShortName: "ar-OM-AbdullahNeural",
    Gender: "Male",
    Locale: "ar-OM",
    LocaleName: "Arabic (Oman)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "123",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-QA, AmalNeural)",
    DisplayName: "Amal",
    LocalName: "أمل",
    ShortName: "ar-QA-AmalNeural",
    Gender: "Female",
    Locale: "ar-QA",
    LocaleName: "Arabic (Qatar)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "113",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-QA, MoazNeural)",
    DisplayName: "Moaz",
    LocalName: "معاذ",
    ShortName: "ar-QA-MoazNeural",
    Gender: "Male",
    Locale: "ar-QA",
    LocaleName: "Arabic (Qatar)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "114",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-SA, ZariyahNeural)",
    DisplayName: "Zariyah",
    LocalName: "زارية",
    ShortName: "ar-SA-ZariyahNeural",
    Gender: "Female",
    Locale: "ar-SA",
    LocaleName: "Arabic (Saudi Arabia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "105",
    Description:
      "A clear- sounding voice with great versatility that can adapt to any use case and speak in a way that's easy to understand.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-SA, HamedNeural)",
    DisplayName: "Hamed",
    LocalName: "حامد",
    ShortName: "ar-SA-HamedNeural",
    Gender: "Male",
    Locale: "ar-SA",
    LocaleName: "Arabic (Saudi Arabia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "107",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-SY, AmanyNeural)",
    DisplayName: "Amany",
    LocalName: "أماني",
    ShortName: "ar-SY-AmanyNeural",
    Gender: "Female",
    Locale: "ar-SY",
    LocaleName: "Arabic (Syria)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "122",
    Description: "A young voice with a wide range of expressions",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-SY, LaithNeural)",
    DisplayName: "Laith",
    LocalName: "ليث",
    ShortName: "ar-SY-LaithNeural",
    Gender: "Male",
    Locale: "ar-SY",
    LocaleName: "Arabic (Syria)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "133",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-TN, ReemNeural)",
    DisplayName: "Reem",
    LocalName: "ريم",
    ShortName: "ar-TN-ReemNeural",
    Gender: "Female",
    Locale: "ar-TN",
    LocaleName: "Arabic (Tunisia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "112",
    Description: "A young voice with a wide range of expressions",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-TN, HediNeural)",
    DisplayName: "Hedi",
    LocalName: "هادي",
    ShortName: "ar-TN-HediNeural",
    Gender: "Male",
    Locale: "ar-TN",
    LocaleName: "Arabic (Tunisia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "118",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-YE, MaryamNeural)",
    DisplayName: "Maryam",
    LocalName: "مريم",
    ShortName: "ar-YE-MaryamNeural",
    Gender: "Female",
    Locale: "ar-YE",
    LocaleName: "Arabic (Yemen)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "108",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ar-YE, SalehNeural)",
    DisplayName: "Saleh",
    LocalName: "صالح",
    ShortName: "ar-YE-SalehNeural",
    Gender: "Male",
    Locale: "ar-YE",
    LocaleName: "Arabic (Yemen)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "115",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (as-IN, PriyomNeural)",
    DisplayName: "Priyom",
    LocalName: "প্ৰিয়ম",
    ShortName: "as-IN-PriyomNeural",
    Gender: "Male",
    Locale: "as-IN",
    LocaleName: "Assamese (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (as-IN, YashicaNeural)",
    DisplayName: "Yashica",
    LocalName: "যাশিকা",
    ShortName: "as-IN-YashicaNeural",
    Gender: "Female",
    Locale: "as-IN",
    LocaleName: "Assamese (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (az-AZ, BanuNeural)",
    DisplayName: "Banu",
    LocalName: "Banu",
    ShortName: "az-AZ-BanuNeural",
    Gender: "Female",
    Locale: "az-AZ",
    LocaleName: "Azerbaijani (Latin, Azerbaijan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "114",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (az-AZ, BabekNeural)",
    DisplayName: "Babek",
    LocalName: "Babək",
    ShortName: "az-AZ-BabekNeural",
    Gender: "Male",
    Locale: "az-AZ",
    LocaleName: "Azerbaijani (Latin, Azerbaijan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "114",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (bg-BG, KalinaNeural)",
    DisplayName: "Kalina",
    LocalName: "Калина",
    ShortName: "bg-BG-KalinaNeural",
    Gender: "Female",
    Locale: "bg-BG",
    LocaleName: "Bulgarian (Bulgaria)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "125",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (bg-BG, BorislavNeural)",
    DisplayName: "Borislav",
    LocalName: "Борислав",
    ShortName: "bg-BG-BorislavNeural",
    Gender: "Male",
    Locale: "bg-BG",
    LocaleName: "Bulgarian (Bulgaria)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "132",
    Description:
      "A friendly voice with slightly whimsical undertones but with a wide expressive range",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (bn-BD, NabanitaNeural)",
    DisplayName: "Nabanita",
    LocalName: "নবনীতা",
    ShortName: "bn-BD-NabanitaNeural",
    Gender: "Female",
    Locale: "bn-BD",
    LocaleName: "Bangla (Bangladesh)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "123",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (bn-BD, PradeepNeural)",
    DisplayName: "Pradeep",
    LocalName: "প্রদ্বীপ",
    ShortName: "bn-BD-PradeepNeural",
    Gender: "Male",
    Locale: "bn-BD",
    LocaleName: "Bangla (Bangladesh)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "125",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (bn-IN, TanishaaNeural)",
    DisplayName: "Tanishaa",
    LocalName: "তানিশা",
    ShortName: "bn-IN-TanishaaNeural",
    Gender: "Female",
    Locale: "bn-IN",
    LocaleName: "Bengali (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "123",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (bn-IN, BashkarNeural)",
    DisplayName: "Bashkar",
    LocalName: "ভাস্কর",
    ShortName: "bn-IN-BashkarNeural",
    Gender: "Male",
    Locale: "bn-IN",
    LocaleName: "Bengali (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "131",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (bs-BA, VesnaNeural)",
    DisplayName: "Vesna",
    LocalName: "Vesna",
    ShortName: "bs-BA-VesnaNeural",
    Gender: "Female",
    Locale: "bs-BA",
    LocaleName: "Bosnian (Bosnia and Herzegovina)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "135",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (bs-BA, GoranNeural)",
    DisplayName: "Goran",
    LocalName: "Goran",
    ShortName: "bs-BA-GoranNeural",
    Gender: "Male",
    Locale: "bs-BA",
    LocaleName: "Bosnian (Bosnia and Herzegovina)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "135",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ca-ES, JoanaNeural)",
    DisplayName: "Joana",
    LocalName: "Joana",
    ShortName: "ca-ES-JoanaNeural",
    Gender: "Female",
    Locale: "ca-ES",
    LocaleName: "Catalan (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "147",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ca-ES, EnricNeural)",
    DisplayName: "Enric",
    LocalName: "Enric",
    ShortName: "ca-ES-EnricNeural",
    Gender: "Male",
    Locale: "ca-ES",
    LocaleName: "Catalan (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "143",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ca-ES, AlbaNeural)",
    DisplayName: "Alba",
    LocalName: "Alba",
    ShortName: "ca-ES-AlbaNeural",
    Gender: "Female",
    Locale: "ca-ES",
    LocaleName: "Catalan (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "155",
    Description:
      "A clear- sounding voice with great versatility that can adapt to any use case and speak in a way that's easy to understand.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (cs-CZ, VlastaNeural)",
    DisplayName: "Vlasta",
    LocalName: "Vlasta",
    ShortName: "cs-CZ-VlastaNeural",
    Gender: "Female",
    Locale: "cs-CZ",
    LocaleName: "Czech (Czechia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "118",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (cs-CZ, AntoninNeural)",
    DisplayName: "Antonin",
    LocalName: "Antonín",
    ShortName: "cs-CZ-AntoninNeural",
    Gender: "Male",
    Locale: "cs-CZ",
    LocaleName: "Czech (Czechia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "143",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (cy-GB, NiaNeural)",
    DisplayName: "Nia",
    LocalName: "Nia",
    ShortName: "cy-GB-NiaNeural",
    Gender: "Female",
    Locale: "cy-GB",
    LocaleName: "Welsh (United Kingdom)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "136",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (cy-GB, AledNeural)",
    DisplayName: "Aled",
    LocalName: "Aled",
    ShortName: "cy-GB-AledNeural",
    Gender: "Male",
    Locale: "cy-GB",
    LocaleName: "Welsh (United Kingdom)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "148",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (da-DK, ChristelNeural)",
    DisplayName: "Christel",
    LocalName: "Christel",
    ShortName: "da-DK-ChristelNeural",
    Gender: "Female",
    Locale: "da-DK",
    LocaleName: "Danish (Denmark)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "155",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (da-DK, JeppeNeural)",
    DisplayName: "Jeppe",
    LocalName: "Jeppe",
    ShortName: "da-DK-JeppeNeural",
    Gender: "Male",
    Locale: "da-DK",
    LocaleName: "Danish (Denmark)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "147",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-AT, IngridNeural)",
    DisplayName: "Ingrid",
    LocalName: "Ingrid",
    ShortName: "de-AT-IngridNeural",
    Gender: "Female",
    Locale: "de-AT",
    LocaleName: "German (Austria)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "128",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-AT, JonasNeural)",
    DisplayName: "Jonas",
    LocalName: "Jonas",
    ShortName: "de-AT-JonasNeural",
    Gender: "Male",
    Locale: "de-AT",
    LocaleName: "German (Austria)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "130",
    Description:
      "A friendly voice with slightly whimsical undertones but with a wide expressive range",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-CH, LeniNeural)",
    DisplayName: "Leni",
    LocalName: "Leni",
    ShortName: "de-CH-LeniNeural",
    Gender: "Female",
    Locale: "de-CH",
    LocaleName: "German (Switzerland)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "121",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-CH, JanNeural)",
    DisplayName: "Jan",
    LocalName: "Jan",
    ShortName: "de-CH-JanNeural",
    Gender: "Male",
    Locale: "de-CH",
    LocaleName: "German (Switzerland)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "135",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, KatjaNeural)",
    DisplayName: "Katja",
    LocalName: "Katja",
    ShortName: "de-DE-KatjaNeural",
    Gender: "Female",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "121",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, ConradNeural)",
    DisplayName: "Conrad",
    LocalName: "Conrad",
    ShortName: "de-DE-ConradNeural",
    Gender: "Male",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    StyleList: ["cheerful"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "128",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, AmalaNeural)",
    DisplayName: "Amala",
    LocalName: "Amala",
    ShortName: "de-DE-AmalaNeural",
    Gender: "Female",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "115",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, BerndNeural)",
    DisplayName: "Bernd",
    LocalName: "Bernd",
    ShortName: "de-DE-BerndNeural",
    Gender: "Male",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "123",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, ChristophNeural)",
    DisplayName: "Christoph",
    LocalName: "Christoph",
    ShortName: "de-DE-ChristophNeural",
    Gender: "Male",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "128",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, ElkeNeural)",
    DisplayName: "Elke",
    LocalName: "Elke",
    ShortName: "de-DE-ElkeNeural",
    Gender: "Female",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "114",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, FlorianMultilingualNeural)",
    DisplayName: "Florian Multilingual",
    LocalName: "Florian Mehrsprachig",
    ShortName: "de-DE-FlorianMultilingualNeural",
    Gender: "Male",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "190",
    Description:
      "A warm and cheerful voice, perfect for chatting or audiobooks, with great versatility to adapt to any use case and speak clearly for easy understanding.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, GiselaNeural)",
    DisplayName: "Gisela",
    LocalName: "Gisela",
    ShortName: "de-DE-GiselaNeural",
    Gender: "Female",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "110",
    Description: "A child voice that's great at conveying curiousity",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, KasperNeural)",
    DisplayName: "Kasper",
    LocalName: "Kasper",
    ShortName: "de-DE-KasperNeural",
    Gender: "Male",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "129",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, KillianNeural)",
    DisplayName: "Killian",
    LocalName: "Killian",
    ShortName: "de-DE-KillianNeural",
    Gender: "Male",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "126",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, KlarissaNeural)",
    DisplayName: "Klarissa",
    LocalName: "Klarissa",
    ShortName: "de-DE-KlarissaNeural",
    Gender: "Female",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "116",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, KlausNeural)",
    DisplayName: "Klaus",
    LocalName: "Klaus",
    ShortName: "de-DE-KlausNeural",
    Gender: "Male",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "106",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, LouisaNeural)",
    DisplayName: "Louisa",
    LocalName: "Louisa",
    ShortName: "de-DE-LouisaNeural",
    Gender: "Female",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "114",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, MajaNeural)",
    DisplayName: "Maja",
    LocalName: "Maja",
    ShortName: "de-DE-MajaNeural",
    Gender: "Female",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "116",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, RalfNeural)",
    DisplayName: "Ralf",
    LocalName: "Ralf",
    ShortName: "de-DE-RalfNeural",
    Gender: "Male",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "127",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, SeraphinaMultilingualNeural)",
    DisplayName: "Seraphina Multilingual",
    LocalName: "Seraphina Mehrsprachig",
    ShortName: "de-DE-SeraphinaMultilingualNeural",
    Gender: "Female",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "190",
    Description:
      "A casually charming voice, ideal for both casual chats and audiobooks, offering a relaxed yet engaging tone.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (de-DE, TanjaNeural)",
    DisplayName: "Tanja",
    LocalName: "Tanja",
    ShortName: "de-DE-TanjaNeural",
    Gender: "Female",
    Locale: "de-DE",
    LocaleName: "German (Germany)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "114",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (el-GR, AthinaNeural)",
    DisplayName: "Athina",
    LocalName: "Αθηνά",
    ShortName: "el-GR-AthinaNeural",
    Gender: "Female",
    Locale: "el-GR",
    LocaleName: "Greek (Greece)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "156",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (el-GR, NestorasNeural)",
    DisplayName: "Nestoras",
    LocalName: "Νέστορας",
    ShortName: "el-GR-NestorasNeural",
    Gender: "Male",
    Locale: "el-GR",
    LocaleName: "Greek (Greece)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "158",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-AU, NatashaNeural)",
    DisplayName: "Natasha",
    LocalName: "Natasha",
    ShortName: "en-AU-NatashaNeural",
    Gender: "Female",
    Locale: "en-AU",
    LocaleName: "English (Australia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "139",
    Description:
      "A clear- sounding voice with great versatility that can adapt to any use case and speak in a way that's easy to understand.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-AU, WilliamNeural)",
    DisplayName: "William",
    LocalName: "William",
    ShortName: "en-AU-WilliamNeural",
    Gender: "Male",
    Locale: "en-AU",
    LocaleName: "English (Australia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "157",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-AU, AnnetteNeural)",
    DisplayName: "Annette",
    LocalName: "Annette",
    ShortName: "en-AU-AnnetteNeural",
    Gender: "Female",
    Locale: "en-AU",
    LocaleName: "English (Australia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "154",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-AU, CarlyNeural)",
    DisplayName: "Carly",
    LocalName: "Carly",
    ShortName: "en-AU-CarlyNeural",
    Gender: "Female",
    Locale: "en-AU",
    LocaleName: "English (Australia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "137",
    Description: "A child voice that's great at conveying curiousity",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-AU, DarrenNeural)",
    DisplayName: "Darren",
    LocalName: "Darren",
    ShortName: "en-AU-DarrenNeural",
    Gender: "Male",
    Locale: "en-AU",
    LocaleName: "English (Australia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "159",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-AU, DuncanNeural)",
    DisplayName: "Duncan",
    LocalName: "Duncan",
    ShortName: "en-AU-DuncanNeural",
    Gender: "Male",
    Locale: "en-AU",
    LocaleName: "English (Australia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "153",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-AU, ElsieNeural)",
    DisplayName: "Elsie",
    LocalName: "Elsie",
    ShortName: "en-AU-ElsieNeural",
    Gender: "Female",
    Locale: "en-AU",
    LocaleName: "English (Australia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "148",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-AU, FreyaNeural)",
    DisplayName: "Freya",
    LocalName: "Freya",
    ShortName: "en-AU-FreyaNeural",
    Gender: "Female",
    Locale: "en-AU",
    LocaleName: "English (Australia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "148",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-AU, JoanneNeural)",
    DisplayName: "Joanne",
    LocalName: "Joanne",
    ShortName: "en-AU-JoanneNeural",
    Gender: "Female",
    Locale: "en-AU",
    LocaleName: "English (Australia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "153",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-AU, KenNeural)",
    DisplayName: "Ken",
    LocalName: "Ken",
    ShortName: "en-AU-KenNeural",
    Gender: "Male",
    Locale: "en-AU",
    LocaleName: "English (Australia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "159",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-AU, KimNeural)",
    DisplayName: "Kim",
    LocalName: "Kim",
    ShortName: "en-AU-KimNeural",
    Gender: "Female",
    Locale: "en-AU",
    LocaleName: "English (Australia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "150",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-AU, NeilNeural)",
    DisplayName: "Neil",
    LocalName: "Neil",
    ShortName: "en-AU-NeilNeural",
    Gender: "Male",
    Locale: "en-AU",
    LocaleName: "English (Australia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "148",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-AU, TimNeural)",
    DisplayName: "Tim",
    LocalName: "Tim",
    ShortName: "en-AU-TimNeural",
    Gender: "Male",
    Locale: "en-AU",
    LocaleName: "English (Australia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "144",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-AU, TinaNeural)",
    DisplayName: "Tina",
    LocalName: "Tina",
    ShortName: "en-AU-TinaNeural",
    Gender: "Female",
    Locale: "en-AU",
    LocaleName: "English (Australia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "136",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-CA, ClaraNeural)",
    DisplayName: "Clara",
    LocalName: "Clara",
    ShortName: "en-CA-ClaraNeural",
    Gender: "Female",
    Locale: "en-CA",
    LocaleName: "English (Canada)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "167",
    Description:
      "A clear- sounding voice with great versatility that can adapt to any use case and speak in a way that's easy to understand.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-CA, LiamNeural)",
    DisplayName: "Liam",
    LocalName: "Liam",
    ShortName: "en-CA-LiamNeural",
    Gender: "Male",
    Locale: "en-CA",
    LocaleName: "English (Canada)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "180",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, SoniaNeural)",
    DisplayName: "Sonia",
    LocalName: "Sonia",
    ShortName: "en-GB-SoniaNeural",
    Gender: "Female",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    StyleList: ["cheerful", "sad"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "150",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, RyanNeural)",
    DisplayName: "Ryan",
    LocalName: "Ryan",
    ShortName: "en-GB-RyanNeural",
    Gender: "Male",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    StyleList: ["cheerful", "chat"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "161",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, LibbyNeural)",
    DisplayName: "Libby",
    LocalName: "Libby",
    ShortName: "en-GB-LibbyNeural",
    Gender: "Female",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "138",
    Description:
      "A clear- sounding voice with great versatility that can adapt to any use case and speak in a way that's easy to understand.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, AbbiNeural)",
    DisplayName: "Abbi",
    LocalName: "Abbi",
    ShortName: "en-GB-AbbiNeural",
    Gender: "Female",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "145",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, AdaMultilingualNeural)",
    DisplayName: "Ada Multilingual",
    LocalName: "Ada Multilingual",
    ShortName: "en-GB-AdaMultilingualNeural",
    Gender: "Female",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "GA",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, AlfieNeural)",
    DisplayName: "Alfie",
    LocalName: "Alfie",
    ShortName: "en-GB-AlfieNeural",
    Gender: "Male",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "157",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, BellaNeural)",
    DisplayName: "Bella",
    LocalName: "Bella",
    ShortName: "en-GB-BellaNeural",
    Gender: "Female",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "146",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, ElliotNeural)",
    DisplayName: "Elliot",
    LocalName: "Elliot",
    ShortName: "en-GB-ElliotNeural",
    Gender: "Male",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "150",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, EthanNeural)",
    DisplayName: "Ethan",
    LocalName: "Ethan",
    ShortName: "en-GB-EthanNeural",
    Gender: "Male",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "155",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, HollieNeural)",
    DisplayName: "Hollie",
    LocalName: "Hollie",
    ShortName: "en-GB-HollieNeural",
    Gender: "Female",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "144",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, MaisieNeural)",
    DisplayName: "Maisie",
    LocalName: "Maisie",
    ShortName: "en-GB-MaisieNeural",
    Gender: "Female",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "141",
    Description: "A child voice that's great at conveying curiousity.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, NoahNeural)",
    DisplayName: "Noah",
    LocalName: "Noah",
    ShortName: "en-GB-NoahNeural",
    Gender: "Male",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "155",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, OliverNeural)",
    DisplayName: "Oliver",
    LocalName: "Oliver",
    ShortName: "en-GB-OliverNeural",
    Gender: "Male",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "155",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, OliviaNeural)",
    DisplayName: "Olivia",
    LocalName: "Olivia",
    ShortName: "en-GB-OliviaNeural",
    Gender: "Female",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "143",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, ThomasNeural)",
    DisplayName: "Thomas",
    LocalName: "Thomas",
    ShortName: "en-GB-ThomasNeural",
    Gender: "Male",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "154",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, OllieMultilingualNeural)",
    DisplayName: "Ollie Multilingual",
    LocalName: "Ollie Multilingual",
    ShortName: "en-GB-OllieMultilingualNeural",
    Gender: "Male",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-GB, MiaNeural)",
    DisplayName: "Mia",
    LocalName: "Mia",
    ShortName: "en-GB-MiaNeural",
    Gender: "Female",
    Locale: "en-GB",
    LocaleName: "English (United Kingdom)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Deprecated",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-HK, YanNeural)",
    DisplayName: "Yan",
    LocalName: "Yan",
    ShortName: "en-HK-YanNeural",
    Gender: "Female",
    Locale: "en-HK",
    LocaleName: "English (Hong Kong SAR)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "144",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-HK, SamNeural)",
    DisplayName: "Sam",
    LocalName: "Sam",
    ShortName: "en-HK-SamNeural",
    Gender: "Male",
    Locale: "en-HK",
    LocaleName: "English (Hong Kong SAR)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "140",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-IE, EmilyNeural)",
    DisplayName: "Emily",
    LocalName: "Emily",
    ShortName: "en-IE-EmilyNeural",
    Gender: "Female",
    Locale: "en-IE",
    LocaleName: "English (Ireland)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "135",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-IE, ConnorNeural)",
    DisplayName: "Connor",
    LocalName: "Connor",
    ShortName: "en-IE-ConnorNeural",
    Gender: "Male",
    Locale: "en-IE",
    LocaleName: "English (Ireland)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "146",
    Description:
      "A friendly voice with slightly whimsical undertones but with a wide expressive range",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-IN, NeerjaNeural)",
    DisplayName: "Neerja",
    LocalName: "Neerja",
    ShortName: "en-IN-NeerjaNeural",
    Gender: "Female",
    Locale: "en-IN",
    LocaleName: "English (India)",
    StyleList: ["newscast", "cheerful", "empathetic"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-IN, PrabhatNeural)",
    DisplayName: "Prabhat",
    LocalName: "Prabhat",
    ShortName: "en-IN-PrabhatNeural",
    Gender: "Male",
    Locale: "en-IN",
    LocaleName: "English (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "129",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-IN, AaravNeural)",
    DisplayName: "Aarav",
    LocalName: "Aarav",
    ShortName: "en-IN-AaravNeural",
    Gender: "Male",
    Locale: "en-IN",
    LocaleName: "English (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-IN, AashiNeural)",
    DisplayName: "Aashi",
    LocalName: "Aashi",
    ShortName: "en-IN-AashiNeural",
    Gender: "Female",
    Locale: "en-IN",
    LocaleName: "English (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-IN, AnanyaNeural)",
    DisplayName: "Ananya",
    LocalName: "Ananya",
    ShortName: "en-IN-AnanyaNeural",
    Gender: "Female",
    Locale: "en-IN",
    LocaleName: "English (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-IN, KavyaNeural)",
    DisplayName: "Kavya",
    LocalName: "Kavya",
    ShortName: "en-IN-KavyaNeural",
    Gender: "Female",
    Locale: "en-IN",
    LocaleName: "English (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-IN, KunalNeural)",
    DisplayName: "Kunal",
    LocalName: "Kunal",
    ShortName: "en-IN-KunalNeural",
    Gender: "Male",
    Locale: "en-IN",
    LocaleName: "English (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-IN, RehaanNeural)",
    DisplayName: "Rehaan",
    LocalName: "Rehaan",
    ShortName: "en-IN-RehaanNeural",
    Gender: "Male",
    Locale: "en-IN",
    LocaleName: "English (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-KE, AsiliaNeural)",
    DisplayName: "Asilia",
    LocalName: "Asilia",
    ShortName: "en-KE-AsiliaNeural",
    Gender: "Female",
    Locale: "en-KE",
    LocaleName: "English (Kenya)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "143",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-KE, ChilembaNeural)",
    DisplayName: "Chilemba",
    LocalName: "Chilemba",
    ShortName: "en-KE-ChilembaNeural",
    Gender: "Male",
    Locale: "en-KE",
    LocaleName: "English (Kenya)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "156",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-NG, EzinneNeural)",
    DisplayName: "Ezinne",
    LocalName: "Ezinne",
    ShortName: "en-NG-EzinneNeural",
    Gender: "Female",
    Locale: "en-NG",
    LocaleName: "English (Nigeria)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "142",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-NG, AbeoNeural)",
    DisplayName: "Abeo",
    LocalName: "Abeo",
    ShortName: "en-NG-AbeoNeural",
    Gender: "Male",
    Locale: "en-NG",
    LocaleName: "English (Nigeria)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "153",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-NZ, MollyNeural)",
    DisplayName: "Molly",
    LocalName: "Molly",
    ShortName: "en-NZ-MollyNeural",
    Gender: "Female",
    Locale: "en-NZ",
    LocaleName: "English (New Zealand)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "132",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-NZ, MitchellNeural)",
    DisplayName: "Mitchell",
    LocalName: "Mitchell",
    ShortName: "en-NZ-MitchellNeural",
    Gender: "Male",
    Locale: "en-NZ",
    LocaleName: "English (New Zealand)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "149",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-PH, RosaNeural)",
    DisplayName: "Rosa",
    LocalName: "Rosa",
    ShortName: "en-PH-RosaNeural",
    Gender: "Female",
    Locale: "en-PH",
    LocaleName: "English (Philippines)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "137",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-PH, JamesNeural)",
    DisplayName: "James",
    LocalName: "James",
    ShortName: "en-PH-JamesNeural",
    Gender: "Male",
    Locale: "en-PH",
    LocaleName: "English (Philippines)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "147",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-SG, LunaNeural)",
    DisplayName: "Luna",
    LocalName: "Luna",
    ShortName: "en-SG-LunaNeural",
    Gender: "Female",
    Locale: "en-SG",
    LocaleName: "English (Singapore)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "133",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-SG, WayneNeural)",
    DisplayName: "Wayne",
    LocalName: "Wayne",
    ShortName: "en-SG-WayneNeural",
    Gender: "Male",
    Locale: "en-SG",
    LocaleName: "English (Singapore)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "133",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-TZ, ImaniNeural)",
    DisplayName: "Imani",
    LocalName: "Imani",
    ShortName: "en-TZ-ImaniNeural",
    Gender: "Female",
    Locale: "en-TZ",
    LocaleName: "English (Tanzania)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "142",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-TZ, ElimuNeural)",
    DisplayName: "Elimu",
    LocalName: "Elimu",
    ShortName: "en-TZ-ElimuNeural",
    Gender: "Male",
    Locale: "en-TZ",
    LocaleName: "English (Tanzania)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "152",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, AvaMultilingualNeural)",
    DisplayName: "Ava Multilingual",
    LocalName: "Ava Multilingual",
    ShortName: "en-US-AvaMultilingualNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    Description:
      "A bright, engaging voice with a beautiful tone that's perfect for delivering search results and capturing users' attention.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, AndrewMultilingualNeural)",
    DisplayName: "Andrew Multilingual",
    LocalName: "Andrew Multilingual",
    ShortName: "en-US-AndrewMultilingualNeural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    Description:
      "A warm, engaging voice that sounds like someone you want to know, perfect for building a connection with listeners.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, EmmaMultilingualNeural)",
    DisplayName: "Emma Multilingual",
    LocalName: "Emma Multilingual",
    ShortName: "en-US-EmmaMultilingualNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    Description:
      "A friendly, sincere voice with a light- hearted and pleasant tone that's ideal for education and explanations.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, BrianMultilingualNeural)",
    DisplayName: "Brian Multilingual",
    LocalName: "Brian Multilingual",
    ShortName: "en-US-BrianMultilingualNeural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    Description:
      "A youthful, cheerful, and versatile voice that can handle any task you throw its way, well- suited to a wide variety of contexts.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, AvaNeural)",
    DisplayName: "Ava",
    LocalName: "Ava",
    ShortName: "en-US-AvaNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    Description:
      "A bright, engaging voice with a beautiful tone that's perfect for delivering search results and capturing users' attention.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, AndrewNeural)",
    DisplayName: "Andrew",
    LocalName: "Andrew",
    ShortName: "en-US-AndrewNeural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    Description:
      "A warm, engaging voice that sounds like someone you want to know, perfect for building a connection with listeners.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, EmmaNeural)",
    DisplayName: "Emma",
    LocalName: "Emma",
    ShortName: "en-US-EmmaNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    Description:
      "A friendly, sincere voice with a light- hearted and pleasant tone that's ideal for education and explanations.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, BrianNeural)",
    DisplayName: "Brian",
    LocalName: "Brian",
    ShortName: "en-US-BrianNeural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    Description:
      "A youthful, cheerful, and versatile voice that can handle any task you throw its way, well- suited to a wide variety of contexts.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, JennyNeural)",
    DisplayName: "Jenny",
    LocalName: "Jenny",
    ShortName: "en-US-JennyNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    StyleList: [
      "assistant",
      "chat",
      "customerservice",
      "newscast",
      "angry",
      "cheerful",
      "sad",
      "excited",
      "friendly",
      "terrified",
      "shouting",
      "unfriendly",
      "whispering",
      "hopeful",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "152",
    Description:
      "A youthful voice with a wide range of expressions, perfect for customer service and keeping users satisfied.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, GuyNeural)",
    DisplayName: "Guy",
    LocalName: "Guy",
    ShortName: "en-US-GuyNeural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    StyleList: [
      "newscast",
      "angry",
      "cheerful",
      "sad",
      "excited",
      "friendly",
      "terrified",
      "shouting",
      "unfriendly",
      "whispering",
      "hopeful",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "215",
    Description:
      "A friendly voice with slightly whimsical undertones and a wide expressive range that can convey any emotion with ease.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, AriaNeural)",
    DisplayName: "Aria",
    LocalName: "Aria",
    ShortName: "en-US-AriaNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    StyleList: [
      "chat",
      "customerservice",
      "narration-professional",
      "newscast-casual",
      "newscast-formal",
      "cheerful",
      "empathetic",
      "angry",
      "sad",
      "excited",
      "friendly",
      "terrified",
      "shouting",
      "unfriendly",
      "whispering",
      "hopeful",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "150",
    Description:
      "A clear- sounding voice with great versatility that can adapt to any use case and speak in a way that's easy to understand.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, DavisNeural)",
    DisplayName: "Davis",
    LocalName: "Davis",
    ShortName: "en-US-DavisNeural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    StyleList: [
      "chat",
      "angry",
      "cheerful",
      "excited",
      "friendly",
      "hopeful",
      "sad",
      "shouting",
      "terrified",
      "unfriendly",
      "whispering",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "154",
    Description:
      "A generally calm and relaxed voice that can switch between tones seamlessly and be highly expressive when needed.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, JaneNeural)",
    DisplayName: "Jane",
    LocalName: "Jane",
    ShortName: "en-US-JaneNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    StyleList: [
      "angry",
      "cheerful",
      "excited",
      "friendly",
      "hopeful",
      "sad",
      "shouting",
      "terrified",
      "unfriendly",
      "whispering",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "154",
    Description:
      "An early- 20s female voice like the girl next door that's warm and friendly, great for building a connection with users.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, JasonNeural)",
    DisplayName: "Jason",
    LocalName: "Jason",
    ShortName: "en-US-JasonNeural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    StyleList: [
      "angry",
      "cheerful",
      "excited",
      "friendly",
      "hopeful",
      "sad",
      "shouting",
      "terrified",
      "unfriendly",
      "whispering",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "156",
    Description:
      "An early- 20s male voice that's polite and unassuming, perhaps a little shy, with a respectful and professional tone that leaves a good impression.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, SaraNeural)",
    DisplayName: "Sara",
    LocalName: "Sara",
    ShortName: "en-US-SaraNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    StyleList: [
      "angry",
      "cheerful",
      "excited",
      "friendly",
      "hopeful",
      "sad",
      "shouting",
      "terrified",
      "unfriendly",
      "whispering",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "157",
    Description:
      "A female teenager voice with a wide range of expressive capabilities that can convey any emotion with ease and keep users engaged.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, TonyNeural)",
    DisplayName: "Tony",
    LocalName: "Tony",
    ShortName: "en-US-TonyNeural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    StyleList: [
      "angry",
      "cheerful",
      "excited",
      "friendly",
      "hopeful",
      "sad",
      "shouting",
      "terrified",
      "unfriendly",
      "whispering",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "156",
    Description:
      "A versatile voice that can sound both casual and professional, adaptable to any use case and situation.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, NancyNeural)",
    DisplayName: "Nancy",
    LocalName: "Nancy",
    ShortName: "en-US-NancyNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    StyleList: [
      "angry",
      "cheerful",
      "excited",
      "friendly",
      "hopeful",
      "sad",
      "shouting",
      "terrified",
      "unfriendly",
      "whispering",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "149",
    Description:
      "A confident- sounding voice that's perfect for delivering important information with a professional and authoritative tone that inspires trust.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, AmberNeural)",
    DisplayName: "Amber",
    LocalName: "Amber",
    ShortName: "en-US-AmberNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "152",
    Description:
      "An engaging voice for children's stories that's warm and approachable, perfect for capturing the attention of young listeners.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, AnaNeural)",
    DisplayName: "Ana",
    LocalName: "Ana",
    ShortName: "en-US-AnaNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "135",
    Description:
      "A child voice that's great at conveying curiosity and engaging users with a fun and playful tone that's sure to delight.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, AshleyNeural)",
    DisplayName: "Ashley",
    LocalName: "Ashley",
    ShortName: "en-US-AshleyNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "149",
    Description:
      "A young voice that sounds a little shy but honest and sincere, conveying a sense of authenticity and approachability.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, BrandonNeural)",
    DisplayName: "Brandon",
    LocalName: "Brandon",
    ShortName: "en-US-BrandonNeural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "156",
    Description:
      "An honest and soft- spoken voice that's warm and good for conversation, connecting with users on a personal level and building trust.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, ChristopherNeural)",
    DisplayName: "Christopher",
    LocalName: "Christopher",
    ShortName: "en-US-ChristopherNeural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "149",
    Description:
      "A warm voice for imparting information, especially for conversation, great for conveying information in a fun and approachable way.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, CoraNeural)",
    DisplayName: "Cora",
    LocalName: "Cora",
    ShortName: "en-US-CoraNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "146",
    Description:
      "A softer voice with a touch of melancholy that conveys understanding and empathy, delivering content in a sensitive and compassionate way.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, ElizabethNeural)",
    DisplayName: "Elizabeth",
    LocalName: "Elizabeth",
    ShortName: "en-US-ElizabethNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "152",
    Description:
      "A friendly voice that conveys soft- spoken confidence, inspiring trust and reliability with a calm and collected tone.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, EricNeural)",
    DisplayName: "Eric",
    LocalName: "Eric",
    ShortName: "en-US-EricNeural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "147",
    Description:
      "A friendly voice that conveys soft- spoken confidence, inspiring trust and reliability with a calm and collected tone.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, JacobNeural)",
    DisplayName: "Jacob",
    LocalName: "Jacob",
    ShortName: "en-US-JacobNeural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "154",
    Description:
      "A mature voice that conveys a strong sense of believability, delivering content in a way that's straightforward and to the point.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, JennyMultilingualNeural)",
    DisplayName: "Jenny Multilingual",
    LocalName: "Jenny Multilingual",
    ShortName: "en-US-JennyMultilingualNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SecondaryLocaleList: [
      "ar-EG",
      "ar-SA",
      "ca-ES",
      "cs-CZ",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-HK",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "fi-FI",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "hi-IN",
      "hu-HU",
      "id-ID",
      "it-IT",
      "ja-JP",
      "ko-KR",
      "nb-NO",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "pt-BR",
      "pt-PT",
      "ru-RU",
      "sv-SE",
      "th-TH",
      "tr-TR",
      "zh-CN",
      "zh-HK",
      "zh-TW",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "190",
    Description:
      "A youthful voice with a wide range of expressions, perfect for customer service and keeping users satisfied.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, MichelleNeural)",
    DisplayName: "Michelle",
    LocalName: "Michelle",
    ShortName: "en-US-MichelleNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "154",
    Description: "An honest voice that conveys confidence and understanding.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, MonicaNeural)",
    DisplayName: "Monica",
    LocalName: "Monica",
    ShortName: "en-US-MonicaNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "145",
    Description:
      "A mature voice that conveys a strong sense of believability, perfect for delivering content in the best possible way",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, RogerNeural)",
    DisplayName: "Roger",
    LocalName: "Roger",
    ShortName: "en-US-RogerNeural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    Description:
      "A friendly voice that conveys information in a approachable manner",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, RyanMultilingualNeural)",
    DisplayName: "Ryan Multilingual",
    LocalName: "Ryan Multilingual",
    ShortName: "en-US-RyanMultilingualNeural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SecondaryLocaleList: [
      "ar-EG",
      "ar-SA",
      "ca-ES",
      "cs-CZ",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-HK",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "fi-FI",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "hi-IN",
      "hu-HU",
      "id-ID",
      "it-IT",
      "ja-JP",
      "ko-KR",
      "nb-NO",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "pt-BR",
      "pt-PT",
      "ru-RU",
      "sv-SE",
      "th-TH",
      "tr-TR",
      "zh-CN",
      "zh-HK",
      "zh-TW",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "190",
    Description:
      "A straightforward voice that works well for delivering information quickly and concisely",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, SteffanNeural)",
    DisplayName: "Steffan",
    LocalName: "Steffan",
    ShortName: "en-US-SteffanNeural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "154",
    Description:
      "A great voice for imparting information, especially in a learning environment.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, AIGenerate1Neural)",
    DisplayName: "AIGenerate1",
    LocalName: "AIGenerate1",
    ShortName: "en-US-AIGenerate1Neural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
    WordsPerMinute: "135",
    Description:
      "A voice that speaks clearly and carefully that can adapt to a wide variety of use cases.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, AIGenerate2Neural)",
    DisplayName: "AIGenerate2",
    LocalName: "AIGenerate2",
    ShortName: "en-US-AIGenerate2Neural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
    WordsPerMinute: "140",
    Description:
      "A somewhat serious voice to convey information in an objective manner",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, BlueNeural)",
    DisplayName: "Blue",
    LocalName: "Blue",
    ShortName: "en-US-BlueNeural",
    Gender: "Neutral",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
    Description:
      "An objective neutral-sounding voice, good for conveying content without bias.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, KaiNeural)",
    DisplayName: "Kai",
    LocalName: "Kai",
    ShortName: "en-US-KaiNeural",
    Gender: "Male",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-US, LunaNeural)",
    DisplayName: "Luna",
    LocalName: "Luna",
    ShortName: "en-US-LunaNeural",
    Gender: "Female",
    Locale: "en-US",
    LocaleName: "English (United States)",
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-ZA, LeahNeural)",
    DisplayName: "Leah",
    LocalName: "Leah",
    ShortName: "en-ZA-LeahNeural",
    Gender: "Female",
    Locale: "en-ZA",
    LocaleName: "English (South Africa)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "143",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (en-ZA, LukeNeural)",
    DisplayName: "Luke",
    LocalName: "Luke",
    ShortName: "en-ZA-LukeNeural",
    Gender: "Male",
    Locale: "en-ZA",
    LocaleName: "English (South Africa)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "168",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-AR, ElenaNeural)",
    DisplayName: "Elena",
    LocalName: "Elena",
    ShortName: "es-AR-ElenaNeural",
    Gender: "Female",
    Locale: "es-AR",
    LocaleName: "Spanish (Argentina)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "150",
    Description:
      "A clear- sounding voice with great versatility that can adapt to any use case and speak in a way that's easy to understand.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-AR, TomasNeural)",
    DisplayName: "Tomas",
    LocalName: "Tomas",
    ShortName: "es-AR-TomasNeural",
    Gender: "Male",
    Locale: "es-AR",
    LocaleName: "Spanish (Argentina)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "158",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-BO, SofiaNeural)",
    DisplayName: "Sofia",
    LocalName: "Sofia",
    ShortName: "es-BO-SofiaNeural",
    Gender: "Female",
    Locale: "es-BO",
    LocaleName: "Spanish (Bolivia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "159",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-BO, MarceloNeural)",
    DisplayName: "Marcelo",
    LocalName: "Marcelo",
    ShortName: "es-BO-MarceloNeural",
    Gender: "Male",
    Locale: "es-BO",
    LocaleName: "Spanish (Bolivia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "152",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-CL, CatalinaNeural)",
    DisplayName: "Catalina",
    LocalName: "Catalina",
    ShortName: "es-CL-CatalinaNeural",
    Gender: "Female",
    Locale: "es-CL",
    LocaleName: "Spanish (Chile)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "295",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-CL, LorenzoNeural)",
    DisplayName: "Lorenzo",
    LocalName: "Lorenzo",
    ShortName: "es-CL-LorenzoNeural",
    Gender: "Male",
    Locale: "es-CL",
    LocaleName: "Spanish (Chile)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "318",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-CO, SalomeNeural)",
    DisplayName: "Salome",
    LocalName: "Salome",
    ShortName: "es-CO-SalomeNeural",
    Gender: "Female",
    Locale: "es-CO",
    LocaleName: "Spanish (Colombia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "143",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-CO, GonzaloNeural)",
    DisplayName: "Gonzalo",
    LocalName: "Gonzalo",
    ShortName: "es-CO-GonzaloNeural",
    Gender: "Male",
    Locale: "es-CO",
    LocaleName: "Spanish (Colombia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "161",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-CR, MariaNeural)",
    DisplayName: "Maria",
    LocalName: "María",
    ShortName: "es-CR-MariaNeural",
    Gender: "Female",
    Locale: "es-CR",
    LocaleName: "Spanish (Costa Rica)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "138",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-CR, JuanNeural)",
    DisplayName: "Juan",
    LocalName: "Juan",
    ShortName: "es-CR-JuanNeural",
    Gender: "Male",
    Locale: "es-CR",
    LocaleName: "Spanish (Costa Rica)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "133",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-CU, BelkysNeural)",
    DisplayName: "Belkys",
    LocalName: "Belkys",
    ShortName: "es-CU-BelkysNeural",
    Gender: "Female",
    Locale: "es-CU",
    LocaleName: "Spanish (Cuba)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "155",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-CU, ManuelNeural)",
    DisplayName: "Manuel",
    LocalName: "Manuel",
    ShortName: "es-CU-ManuelNeural",
    Gender: "Male",
    Locale: "es-CU",
    LocaleName: "Spanish (Cuba)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "137",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-DO, RamonaNeural)",
    DisplayName: "Ramona",
    LocalName: "Ramona",
    ShortName: "es-DO-RamonaNeural",
    Gender: "Female",
    Locale: "es-DO",
    LocaleName: "Spanish (Dominican Republic)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "130",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-DO, EmilioNeural)",
    DisplayName: "Emilio",
    LocalName: "Emilio",
    ShortName: "es-DO-EmilioNeural",
    Gender: "Male",
    Locale: "es-DO",
    LocaleName: "Spanish (Dominican Republic)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "133",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-EC, AndreaNeural)",
    DisplayName: "Andrea",
    LocalName: "Andrea",
    ShortName: "es-EC-AndreaNeural",
    Gender: "Female",
    Locale: "es-EC",
    LocaleName: "Spanish (Ecuador)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "152",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-EC, LuisNeural)",
    DisplayName: "Luis",
    LocalName: "Luis",
    ShortName: "es-EC-LuisNeural",
    Gender: "Male",
    Locale: "es-EC",
    LocaleName: "Spanish (Ecuador)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "138",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, ElviraNeural)",
    DisplayName: "Elvira",
    LocalName: "Elvira",
    ShortName: "es-ES-ElviraNeural",
    Gender: "Female",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "148",
    Description:
      "A clear- sounding voice with great versatility that can adapt to any use case and speak in a way that's easy to understand.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, AlvaroNeural)",
    DisplayName: "Alvaro",
    LocalName: "Álvaro",
    ShortName: "es-ES-AlvaroNeural",
    Gender: "Male",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "148",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, AbrilNeural)",
    DisplayName: "Abril",
    LocalName: "Abril",
    ShortName: "es-ES-AbrilNeural",
    Gender: "Female",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "146",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, ArnauNeural)",
    DisplayName: "Arnau",
    LocalName: "Arnau",
    ShortName: "es-ES-ArnauNeural",
    Gender: "Male",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "142",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, DarioNeural)",
    DisplayName: "Dario",
    LocalName: "Dario",
    ShortName: "es-ES-DarioNeural",
    Gender: "Male",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "164",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, EliasNeural)",
    DisplayName: "Elias",
    LocalName: "Elias",
    ShortName: "es-ES-EliasNeural",
    Gender: "Male",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "143",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, EstrellaNeural)",
    DisplayName: "Estrella",
    LocalName: "Estrella",
    ShortName: "es-ES-EstrellaNeural",
    Gender: "Female",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "143",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, IreneNeural)",
    DisplayName: "Irene",
    LocalName: "Irene",
    ShortName: "es-ES-IreneNeural",
    Gender: "Female",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "134",
    Description: "A child voice that's great at conveying curiousity",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, LaiaNeural)",
    DisplayName: "Laia",
    LocalName: "Laia",
    ShortName: "es-ES-LaiaNeural",
    Gender: "Female",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "141",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, LiaNeural)",
    DisplayName: "Lia",
    LocalName: "Lia",
    ShortName: "es-ES-LiaNeural",
    Gender: "Female",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "150",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, NilNeural)",
    DisplayName: "Nil",
    LocalName: "Nil",
    ShortName: "es-ES-NilNeural",
    Gender: "Male",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "149",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, SaulNeural)",
    DisplayName: "Saul",
    LocalName: "Saul",
    ShortName: "es-ES-SaulNeural",
    Gender: "Male",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "148",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, TeoNeural)",
    DisplayName: "Teo",
    LocalName: "Teo",
    ShortName: "es-ES-TeoNeural",
    Gender: "Male",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "155",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, TrianaNeural)",
    DisplayName: "Triana",
    LocalName: "Triana",
    ShortName: "es-ES-TrianaNeural",
    Gender: "Female",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "150",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, VeraNeural)",
    DisplayName: "Vera",
    LocalName: "Vera",
    ShortName: "es-ES-VeraNeural",
    Gender: "Female",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "152",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, XimenaNeural)",
    DisplayName: "Ximena",
    LocalName: "Ximena",
    ShortName: "es-ES-XimenaNeural",
    Gender: "Female",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, ArabellaMultilingualNeural)",
    DisplayName: "Arabella Multilingual",
    LocalName: "Arabella Multilingual",
    ShortName: "es-ES-ArabellaMultilingualNeural",
    Gender: "Female",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-ES, IsidoraMultilingualNeural)",
    DisplayName: "Isidora Multilingual",
    LocalName: "Isidora Multilingual",
    ShortName: "es-ES-IsidoraMultilingualNeural",
    Gender: "Female",
    Locale: "es-ES",
    LocaleName: "Spanish (Spain)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-GQ, TeresaNeural)",
    DisplayName: "Teresa",
    LocalName: "Teresa",
    ShortName: "es-GQ-TeresaNeural",
    Gender: "Female",
    Locale: "es-GQ",
    LocaleName: "Spanish (Equatorial Guinea)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "155",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-GQ, JavierNeural)",
    DisplayName: "Javier",
    LocalName: "Javier",
    ShortName: "es-GQ-JavierNeural",
    Gender: "Male",
    Locale: "es-GQ",
    LocaleName: "Spanish (Equatorial Guinea)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "129",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-GT, MartaNeural)",
    DisplayName: "Marta",
    LocalName: "Marta",
    ShortName: "es-GT-MartaNeural",
    Gender: "Female",
    Locale: "es-GT",
    LocaleName: "Spanish (Guatemala)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "159",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-GT, AndresNeural)",
    DisplayName: "Andres",
    LocalName: "Andrés",
    ShortName: "es-GT-AndresNeural",
    Gender: "Male",
    Locale: "es-GT",
    LocaleName: "Spanish (Guatemala)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "157",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-HN, KarlaNeural)",
    DisplayName: "Karla",
    LocalName: "Karla",
    ShortName: "es-HN-KarlaNeural",
    Gender: "Female",
    Locale: "es-HN",
    LocaleName: "Spanish (Honduras)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "137",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-HN, CarlosNeural)",
    DisplayName: "Carlos",
    LocalName: "Carlos",
    ShortName: "es-HN-CarlosNeural",
    Gender: "Male",
    Locale: "es-HN",
    LocaleName: "Spanish (Honduras)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "157",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-MX, DaliaNeural)",
    DisplayName: "Dalia",
    LocalName: "Dalia",
    ShortName: "es-MX-DaliaNeural",
    Gender: "Female",
    Locale: "es-MX",
    LocaleName: "Spanish (Mexico)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "145",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-MX, JorgeNeural)",
    DisplayName: "Jorge",
    LocalName: "Jorge",
    ShortName: "es-MX-JorgeNeural",
    Gender: "Male",
    Locale: "es-MX",
    LocaleName: "Spanish (Mexico)",
    StyleList: ["cheerful", "chat"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "143",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-MX, BeatrizNeural)",
    DisplayName: "Beatriz",
    LocalName: "Beatriz",
    ShortName: "es-MX-BeatrizNeural",
    Gender: "Female",
    Locale: "es-MX",
    LocaleName: "Spanish (Mexico)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "157",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-MX, CandelaNeural)",
    DisplayName: "Candela",
    LocalName: "Candela",
    ShortName: "es-MX-CandelaNeural",
    Gender: "Female",
    Locale: "es-MX",
    LocaleName: "Spanish (Mexico)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "134",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-MX, CarlotaNeural)",
    DisplayName: "Carlota",
    LocalName: "Carlota",
    ShortName: "es-MX-CarlotaNeural",
    Gender: "Female",
    Locale: "es-MX",
    LocaleName: "Spanish (Mexico)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "145",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-MX, CecilioNeural)",
    DisplayName: "Cecilio",
    LocalName: "Cecilio",
    ShortName: "es-MX-CecilioNeural",
    Gender: "Male",
    Locale: "es-MX",
    LocaleName: "Spanish (Mexico)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "149",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-MX, GerardoNeural)",
    DisplayName: "Gerardo",
    LocalName: "Gerardo",
    ShortName: "es-MX-GerardoNeural",
    Gender: "Male",
    Locale: "es-MX",
    LocaleName: "Spanish (Mexico)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "141",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-MX, LarissaNeural)",
    DisplayName: "Larissa",
    LocalName: "Larissa",
    ShortName: "es-MX-LarissaNeural",
    Gender: "Female",
    Locale: "es-MX",
    LocaleName: "Spanish (Mexico)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "151",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-MX, LibertoNeural)",
    DisplayName: "Liberto",
    LocalName: "Liberto",
    ShortName: "es-MX-LibertoNeural",
    Gender: "Male",
    Locale: "es-MX",
    LocaleName: "Spanish (Mexico)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "149",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-MX, LucianoNeural)",
    DisplayName: "Luciano",
    LocalName: "Luciano",
    ShortName: "es-MX-LucianoNeural",
    Gender: "Male",
    Locale: "es-MX",
    LocaleName: "Spanish (Mexico)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "139",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-MX, MarinaNeural)",
    DisplayName: "Marina",
    LocalName: "Marina",
    ShortName: "es-MX-MarinaNeural",
    Gender: "Female",
    Locale: "es-MX",
    LocaleName: "Spanish (Mexico)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "136",
    Description: "A child voice that's great at conveying curiousity",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-MX, NuriaNeural)",
    DisplayName: "Nuria",
    LocalName: "Nuria",
    ShortName: "es-MX-NuriaNeural",
    Gender: "Female",
    Locale: "es-MX",
    LocaleName: "Spanish (Mexico)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "154",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-MX, PelayoNeural)",
    DisplayName: "Pelayo",
    LocalName: "Pelayo",
    ShortName: "es-MX-PelayoNeural",
    Gender: "Male",
    Locale: "es-MX",
    LocaleName: "Spanish (Mexico)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "148",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-MX, RenataNeural)",
    DisplayName: "Renata",
    LocalName: "Renata",
    ShortName: "es-MX-RenataNeural",
    Gender: "Female",
    Locale: "es-MX",
    LocaleName: "Spanish (Mexico)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "153",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-MX, YagoNeural)",
    DisplayName: "Yago",
    LocalName: "Yago",
    ShortName: "es-MX-YagoNeural",
    Gender: "Male",
    Locale: "es-MX",
    LocaleName: "Spanish (Mexico)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "149",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-NI, YolandaNeural)",
    DisplayName: "Yolanda",
    LocalName: "Yolanda",
    ShortName: "es-NI-YolandaNeural",
    Gender: "Female",
    Locale: "es-NI",
    LocaleName: "Spanish (Nicaragua)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "130",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-NI, FedericoNeural)",
    DisplayName: "Federico",
    LocalName: "Federico",
    ShortName: "es-NI-FedericoNeural",
    Gender: "Male",
    Locale: "es-NI",
    LocaleName: "Spanish (Nicaragua)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "152",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-PA, MargaritaNeural)",
    DisplayName: "Margarita",
    LocalName: "Margarita",
    ShortName: "es-PA-MargaritaNeural",
    Gender: "Female",
    Locale: "es-PA",
    LocaleName: "Spanish (Panama)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "152",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-PA, RobertoNeural)",
    DisplayName: "Roberto",
    LocalName: "Roberto",
    ShortName: "es-PA-RobertoNeural",
    Gender: "Male",
    Locale: "es-PA",
    LocaleName: "Spanish (Panama)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "133",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-PE, CamilaNeural)",
    DisplayName: "Camila",
    LocalName: "Camila",
    ShortName: "es-PE-CamilaNeural",
    Gender: "Female",
    Locale: "es-PE",
    LocaleName: "Spanish (Peru)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "159",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-PE, AlexNeural)",
    DisplayName: "Alex",
    LocalName: "Alex",
    ShortName: "es-PE-AlexNeural",
    Gender: "Male",
    Locale: "es-PE",
    LocaleName: "Spanish (Peru)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "133",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-PR, KarinaNeural)",
    DisplayName: "Karina",
    LocalName: "Karina",
    ShortName: "es-PR-KarinaNeural",
    Gender: "Female",
    Locale: "es-PR",
    LocaleName: "Spanish (Puerto Rico)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "130",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-PR, VictorNeural)",
    DisplayName: "Victor",
    LocalName: "Víctor",
    ShortName: "es-PR-VictorNeural",
    Gender: "Male",
    Locale: "es-PR",
    LocaleName: "Spanish (Puerto Rico)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "138",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-PY, TaniaNeural)",
    DisplayName: "Tania",
    LocalName: "Tania",
    ShortName: "es-PY-TaniaNeural",
    Gender: "Female",
    Locale: "es-PY",
    LocaleName: "Spanish (Paraguay)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "151",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-PY, MarioNeural)",
    DisplayName: "Mario",
    LocalName: "Mario",
    ShortName: "es-PY-MarioNeural",
    Gender: "Male",
    Locale: "es-PY",
    LocaleName: "Spanish (Paraguay)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "168",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-SV, LorenaNeural)",
    DisplayName: "Lorena",
    LocalName: "Lorena",
    ShortName: "es-SV-LorenaNeural",
    Gender: "Female",
    Locale: "es-SV",
    LocaleName: "Spanish (El Salvador)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "159",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-SV, RodrigoNeural)",
    DisplayName: "Rodrigo",
    LocalName: "Rodrigo",
    ShortName: "es-SV-RodrigoNeural",
    Gender: "Male",
    Locale: "es-SV",
    LocaleName: "Spanish (El Salvador)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "157",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-US, PalomaNeural)",
    DisplayName: "Paloma",
    LocalName: "Paloma",
    ShortName: "es-US-PalomaNeural",
    Gender: "Female",
    Locale: "es-US",
    LocaleName: "Spanish (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "152",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-US, AlonsoNeural)",
    DisplayName: "Alonso",
    LocalName: "Alonso",
    ShortName: "es-US-AlonsoNeural",
    Gender: "Male",
    Locale: "es-US",
    LocaleName: "Spanish (United States)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "138",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-UY, ValentinaNeural)",
    DisplayName: "Valentina",
    LocalName: "Valentina",
    ShortName: "es-UY-ValentinaNeural",
    Gender: "Female",
    Locale: "es-UY",
    LocaleName: "Spanish (Uruguay)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "150",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-UY, MateoNeural)",
    DisplayName: "Mateo",
    LocalName: "Mateo",
    ShortName: "es-UY-MateoNeural",
    Gender: "Male",
    Locale: "es-UY",
    LocaleName: "Spanish (Uruguay)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "158",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-VE, PaolaNeural)",
    DisplayName: "Paola",
    LocalName: "Paola",
    ShortName: "es-VE-PaolaNeural",
    Gender: "Female",
    Locale: "es-VE",
    LocaleName: "Spanish (Venezuela)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "130",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (es-VE, SebastianNeural)",
    DisplayName: "Sebastian",
    LocalName: "Sebastián",
    ShortName: "es-VE-SebastianNeural",
    Gender: "Male",
    Locale: "es-VE",
    LocaleName: "Spanish (Venezuela)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "157",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (et-EE, AnuNeural)",
    DisplayName: "Anu",
    LocalName: "Anu",
    ShortName: "et-EE-AnuNeural",
    Gender: "Female",
    Locale: "et-EE",
    LocaleName: "Estonian (Estonia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "114",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (et-EE, KertNeural)",
    DisplayName: "Kert",
    LocalName: "Kert",
    ShortName: "et-EE-KertNeural",
    Gender: "Male",
    Locale: "et-EE",
    LocaleName: "Estonian (Estonia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "114",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (eu-ES, AinhoaNeural)",
    DisplayName: "Ainhoa",
    LocalName: "Ainhoa",
    ShortName: "eu-ES-AinhoaNeural",
    Gender: "Female",
    Locale: "eu-ES",
    LocaleName: "Basque",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "102",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (eu-ES, AnderNeural)",
    DisplayName: "Ander",
    LocalName: "Ander",
    ShortName: "eu-ES-AnderNeural",
    Gender: "Male",
    Locale: "eu-ES",
    LocaleName: "Basque",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "102",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fa-IR, DilaraNeural)",
    DisplayName: "Dilara",
    LocalName: "دلارا",
    ShortName: "fa-IR-DilaraNeural",
    Gender: "Female",
    Locale: "fa-IR",
    LocaleName: "Persian (Iran)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "134",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fa-IR, FaridNeural)",
    DisplayName: "Farid",
    LocalName: "فرید",
    ShortName: "fa-IR-FaridNeural",
    Gender: "Male",
    Locale: "fa-IR",
    LocaleName: "Persian (Iran)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "134",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fi-FI, SelmaNeural)",
    DisplayName: "Selma",
    LocalName: "Selma",
    ShortName: "fi-FI-SelmaNeural",
    Gender: "Female",
    Locale: "fi-FI",
    LocaleName: "Finnish (Finland)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "91",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fi-FI, HarriNeural)",
    DisplayName: "Harri",
    LocalName: "Harri",
    ShortName: "fi-FI-HarriNeural",
    Gender: "Male",
    Locale: "fi-FI",
    LocaleName: "Finnish (Finland)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "97",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fi-FI, NooraNeural)",
    DisplayName: "Noora",
    LocalName: "Noora",
    ShortName: "fi-FI-NooraNeural",
    Gender: "Female",
    Locale: "fi-FI",
    LocaleName: "Finnish (Finland)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "96",
    Description:
      "A clear- sounding voice with great versatility that can adapt to any use case and speak in a way that's easy to understand.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fil-PH, BlessicaNeural)",
    DisplayName: "Blessica",
    LocalName: "Blessica",
    ShortName: "fil-PH-BlessicaNeural",
    Gender: "Female",
    Locale: "fil-PH",
    LocaleName: "Filipino (Philippines)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "140",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fil-PH, AngeloNeural)",
    DisplayName: "Angelo",
    LocalName: "Angelo",
    ShortName: "fil-PH-AngeloNeural",
    Gender: "Male",
    Locale: "fil-PH",
    LocaleName: "Filipino (Philippines)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "144",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-BE, CharlineNeural)",
    DisplayName: "Charline",
    LocalName: "Charline",
    ShortName: "fr-BE-CharlineNeural",
    Gender: "Female",
    Locale: "fr-BE",
    LocaleName: "French (Belgium)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "157",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-BE, GerardNeural)",
    DisplayName: "Gerard",
    LocalName: "Gerard",
    ShortName: "fr-BE-GerardNeural",
    Gender: "Male",
    Locale: "fr-BE",
    LocaleName: "French (Belgium)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "172",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-CA, SylvieNeural)",
    DisplayName: "Sylvie",
    LocalName: "Sylvie",
    ShortName: "fr-CA-SylvieNeural",
    Gender: "Female",
    Locale: "fr-CA",
    LocaleName: "French (Canada)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "154",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-CA, JeanNeural)",
    DisplayName: "Jean",
    LocalName: "Jean",
    ShortName: "fr-CA-JeanNeural",
    Gender: "Male",
    Locale: "fr-CA",
    LocaleName: "French (Canada)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "133",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-CA, AntoineNeural)",
    DisplayName: "Antoine",
    LocalName: "Antoine",
    ShortName: "fr-CA-AntoineNeural",
    Gender: "Male",
    Locale: "fr-CA",
    LocaleName: "French (Canada)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "159",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-CA, ThierryNeural)",
    DisplayName: "Thierry",
    LocalName: "Thierry",
    ShortName: "fr-CA-ThierryNeural",
    Gender: "Male",
    Locale: "fr-CA",
    LocaleName: "French (Canada)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-CH, ArianeNeural)",
    DisplayName: "Ariane",
    LocalName: "Ariane",
    ShortName: "fr-CH-ArianeNeural",
    Gender: "Female",
    Locale: "fr-CH",
    LocaleName: "French (Switzerland)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "158",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-CH, FabriceNeural)",
    DisplayName: "Fabrice",
    LocalName: "Fabrice",
    ShortName: "fr-CH-FabriceNeural",
    Gender: "Male",
    Locale: "fr-CH",
    LocaleName: "French (Switzerland)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "172",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-FR, DeniseNeural)",
    DisplayName: "Denise",
    LocalName: "Denise",
    ShortName: "fr-FR-DeniseNeural",
    Gender: "Female",
    Locale: "fr-FR",
    LocaleName: "French (France)",
    StyleList: ["cheerful", "sad"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "155",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-FR, HenriNeural)",
    DisplayName: "Henri",
    LocalName: "Henri",
    ShortName: "fr-FR-HenriNeural",
    Gender: "Male",
    Locale: "fr-FR",
    LocaleName: "French (France)",
    StyleList: ["cheerful", "sad"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "165",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-FR, AlainNeural)",
    DisplayName: "Alain",
    LocalName: "Alain",
    ShortName: "fr-FR-AlainNeural",
    Gender: "Male",
    Locale: "fr-FR",
    LocaleName: "French (France)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "165",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-FR, BrigitteNeural)",
    DisplayName: "Brigitte",
    LocalName: "Brigitte",
    ShortName: "fr-FR-BrigitteNeural",
    Gender: "Female",
    Locale: "fr-FR",
    LocaleName: "French (France)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "153",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-FR, CelesteNeural)",
    DisplayName: "Celeste",
    LocalName: "Celeste",
    ShortName: "fr-FR-CelesteNeural",
    Gender: "Female",
    Locale: "fr-FR",
    LocaleName: "French (France)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "155",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-FR, ClaudeNeural)",
    DisplayName: "Claude",
    LocalName: "Claude",
    ShortName: "fr-FR-ClaudeNeural",
    Gender: "Male",
    Locale: "fr-FR",
    LocaleName: "French (France)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "155",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-FR, CoralieNeural)",
    DisplayName: "Coralie",
    LocalName: "Coralie",
    ShortName: "fr-FR-CoralieNeural",
    Gender: "Female",
    Locale: "fr-FR",
    LocaleName: "French (France)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "154",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-FR, EloiseNeural)",
    DisplayName: "Eloise",
    LocalName: "Eloise",
    ShortName: "fr-FR-EloiseNeural",
    Gender: "Female",
    Locale: "fr-FR",
    LocaleName: "French (France)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "150",
    Description: "A child voice that's great at conveying curiousity",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-FR, JacquelineNeural)",
    DisplayName: "Jacqueline",
    LocalName: "Jacqueline",
    ShortName: "fr-FR-JacquelineNeural",
    Gender: "Female",
    Locale: "fr-FR",
    LocaleName: "French (France)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "154",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-FR, JeromeNeural)",
    DisplayName: "Jerome",
    LocalName: "Jerome",
    ShortName: "fr-FR-JeromeNeural",
    Gender: "Male",
    Locale: "fr-FR",
    LocaleName: "French (France)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "165",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-FR, JosephineNeural)",
    DisplayName: "Josephine",
    LocalName: "Josephine",
    ShortName: "fr-FR-JosephineNeural",
    Gender: "Female",
    Locale: "fr-FR",
    LocaleName: "French (France)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "155",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-FR, MauriceNeural)",
    DisplayName: "Maurice",
    LocalName: "Maurice",
    ShortName: "fr-FR-MauriceNeural",
    Gender: "Male",
    Locale: "fr-FR",
    LocaleName: "French (France)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "162",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-FR, RemyMultilingualNeural)",
    DisplayName: "Remy Multilingual",
    LocalName: "Rémy Multilingue",
    ShortName: "fr-FR-RemyMultilingualNeural",
    Gender: "Male",
    Locale: "fr-FR",
    LocaleName: "French (France)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "190",
    Description:
      "A bright and cheerful voice suitable for both lively chats and audiobooks, bringing an uplifting and cheerful tone to every conversation.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-FR, VivienneMultilingualNeural)",
    DisplayName: "Vivienne Multilingual",
    LocalName: "Vivienne Multilingue",
    ShortName: "fr-FR-VivienneMultilingualNeural",
    Gender: "Female",
    Locale: "fr-FR",
    LocaleName: "French (France)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "190",
    Description:
      "A warm and casual voice perfect for advertisements, creating a welcoming and relaxed atmosphere for listeners.",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-FR, YvesNeural)",
    DisplayName: "Yves",
    LocalName: "Yves",
    ShortName: "fr-FR-YvesNeural",
    Gender: "Male",
    Locale: "fr-FR",
    LocaleName: "French (France)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "162",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (fr-FR, YvetteNeural)",
    DisplayName: "Yvette",
    LocalName: "Yvette",
    ShortName: "fr-FR-YvetteNeural",
    Gender: "Female",
    Locale: "fr-FR",
    LocaleName: "French (France)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "156",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ga-IE, OrlaNeural)",
    DisplayName: "Orla",
    LocalName: "Orla",
    ShortName: "ga-IE-OrlaNeural",
    Gender: "Female",
    Locale: "ga-IE",
    LocaleName: "Irish (Ireland)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "139",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ga-IE, ColmNeural)",
    DisplayName: "Colm",
    LocalName: "Colm",
    ShortName: "ga-IE-ColmNeural",
    Gender: "Male",
    Locale: "ga-IE",
    LocaleName: "Irish (Ireland)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "157",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (gl-ES, SabelaNeural)",
    DisplayName: "Sabela",
    LocalName: "Sabela",
    ShortName: "gl-ES-SabelaNeural",
    Gender: "Female",
    Locale: "gl-ES",
    LocaleName: "Galician",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "141",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (gl-ES, RoiNeural)",
    DisplayName: "Roi",
    LocalName: "Roi",
    ShortName: "gl-ES-RoiNeural",
    Gender: "Male",
    Locale: "gl-ES",
    LocaleName: "Galician",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "148",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (gu-IN, DhwaniNeural)",
    DisplayName: "Dhwani",
    LocalName: "ધ્વની",
    ShortName: "gu-IN-DhwaniNeural",
    Gender: "Female",
    Locale: "gu-IN",
    LocaleName: "Gujarati (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "89",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (gu-IN, NiranjanNeural)",
    DisplayName: "Niranjan",
    LocalName: "નિરંજન",
    ShortName: "gu-IN-NiranjanNeural",
    Gender: "Male",
    Locale: "gu-IN",
    LocaleName: "Gujarati (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "107",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (he-IL, HilaNeural)",
    DisplayName: "Hila",
    LocalName: "הילה",
    ShortName: "he-IL-HilaNeural",
    Gender: "Female",
    Locale: "he-IL",
    LocaleName: "Hebrew (Israel)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "113",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (he-IL, AvriNeural)",
    DisplayName: "Avri",
    LocalName: "אברי",
    ShortName: "he-IL-AvriNeural",
    Gender: "Male",
    Locale: "he-IL",
    LocaleName: "Hebrew (Israel)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "106",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (hi-IN, SwaraNeural)",
    DisplayName: "Swara",
    LocalName: "स्वरा",
    ShortName: "hi-IN-SwaraNeural",
    Gender: "Female",
    Locale: "hi-IN",
    LocaleName: "Hindi (India)",
    StyleList: ["newscast", "cheerful", "empathetic"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (hi-IN, MadhurNeural)",
    DisplayName: "Madhur",
    LocalName: "मधुर",
    ShortName: "hi-IN-MadhurNeural",
    Gender: "Male",
    Locale: "hi-IN",
    LocaleName: "Hindi (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "114",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (hi-IN, AaravNeural)",
    DisplayName: "Aarav",
    LocalName: "आरव ",
    ShortName: "hi-IN-AaravNeural",
    Gender: "Male",
    Locale: "hi-IN",
    LocaleName: "Hindi (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (hi-IN, AnanyaNeural)",
    DisplayName: "Ananya",
    LocalName: "अनन्या",
    ShortName: "hi-IN-AnanyaNeural",
    Gender: "Female",
    Locale: "hi-IN",
    LocaleName: "Hindi (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (hi-IN, KavyaNeural)",
    DisplayName: "Kavya",
    LocalName: "काव्या",
    ShortName: "hi-IN-KavyaNeural",
    Gender: "Female",
    Locale: "hi-IN",
    LocaleName: "Hindi (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (hi-IN, KunalNeural)",
    DisplayName: "Kunal",
    LocalName: "कुनाल ",
    ShortName: "hi-IN-KunalNeural",
    Gender: "Male",
    Locale: "hi-IN",
    LocaleName: "Hindi (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (hi-IN, RehaanNeural)",
    DisplayName: "Rehaan",
    LocalName: "रेहान",
    ShortName: "hi-IN-RehaanNeural",
    Gender: "Male",
    Locale: "hi-IN",
    LocaleName: "Hindi (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (hr-HR, GabrijelaNeural)",
    DisplayName: "Gabrijela",
    LocalName: "Gabrijela",
    ShortName: "hr-HR-GabrijelaNeural",
    Gender: "Female",
    Locale: "hr-HR",
    LocaleName: "Croatian (Croatia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "124",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (hr-HR, SreckoNeural)",
    DisplayName: "Srecko",
    LocalName: "Srećko",
    ShortName: "hr-HR-SreckoNeural",
    Gender: "Male",
    Locale: "hr-HR",
    LocaleName: "Croatian (Croatia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "133",
    Description:
      "A friendly voice with slightly whimsical undertones but with a wide expressive range",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (hu-HU, NoemiNeural)",
    DisplayName: "Noemi",
    LocalName: "Noémi",
    ShortName: "hu-HU-NoemiNeural",
    Gender: "Female",
    Locale: "hu-HU",
    LocaleName: "Hungarian (Hungary)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "110",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (hu-HU, TamasNeural)",
    DisplayName: "Tamas",
    LocalName: "Tamás",
    ShortName: "hu-HU-TamasNeural",
    Gender: "Male",
    Locale: "hu-HU",
    LocaleName: "Hungarian (Hungary)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "124",
    Description:
      "A friendly voice with slightly whimsical undertones but with a wide expressive range",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (hy-AM, AnahitNeural)",
    DisplayName: "Anahit",
    LocalName: "Անահիտ",
    ShortName: "hy-AM-AnahitNeural",
    Gender: "Female",
    Locale: "hy-AM",
    LocaleName: "Armenian (Armenia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "112",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (hy-AM, HaykNeural)",
    DisplayName: "Hayk",
    LocalName: "Հայկ",
    ShortName: "hy-AM-HaykNeural",
    Gender: "Male",
    Locale: "hy-AM",
    LocaleName: "Armenian (Armenia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "112",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (id-ID, GadisNeural)",
    DisplayName: "Gadis",
    LocalName: "Gadis",
    ShortName: "id-ID-GadisNeural",
    Gender: "Female",
    Locale: "id-ID",
    LocaleName: "Indonesian (Indonesia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "111",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (id-ID, ArdiNeural)",
    DisplayName: "Ardi",
    LocalName: "Ardi",
    ShortName: "id-ID-ArdiNeural",
    Gender: "Male",
    Locale: "id-ID",
    LocaleName: "Indonesian (Indonesia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "124",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (is-IS, GudrunNeural)",
    DisplayName: "Gudrun",
    LocalName: "Guðrún",
    ShortName: "is-IS-GudrunNeural",
    Gender: "Female",
    Locale: "is-IS",
    LocaleName: "Icelandic (Iceland)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "135",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (is-IS, GunnarNeural)",
    DisplayName: "Gunnar",
    LocalName: "Gunnar",
    ShortName: "is-IS-GunnarNeural",
    Gender: "Male",
    Locale: "is-IS",
    LocaleName: "Icelandic (Iceland)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "144",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, ElsaNeural)",
    DisplayName: "Elsa",
    LocalName: "Elsa",
    ShortName: "it-IT-ElsaNeural",
    Gender: "Female",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "148",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, IsabellaNeural)",
    DisplayName: "Isabella",
    LocalName: "Isabella",
    ShortName: "it-IT-IsabellaNeural",
    Gender: "Female",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    StyleList: ["cheerful", "chat"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "134",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, DiegoNeural)",
    DisplayName: "Diego",
    LocalName: "Diego",
    ShortName: "it-IT-DiegoNeural",
    Gender: "Male",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "135",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, BenignoNeural)",
    DisplayName: "Benigno",
    LocalName: "Benigno",
    ShortName: "it-IT-BenignoNeural",
    Gender: "Male",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "153",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, CalimeroNeural)",
    DisplayName: "Calimero",
    LocalName: "Calimero",
    ShortName: "it-IT-CalimeroNeural",
    Gender: "Male",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "142",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, CataldoNeural)",
    DisplayName: "Cataldo",
    LocalName: "Cataldo",
    ShortName: "it-IT-CataldoNeural",
    Gender: "Male",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "149",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, FabiolaNeural)",
    DisplayName: "Fabiola",
    LocalName: "Fabiola",
    ShortName: "it-IT-FabiolaNeural",
    Gender: "Female",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "143",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, FiammaNeural)",
    DisplayName: "Fiamma",
    LocalName: "Fiamma",
    ShortName: "it-IT-FiammaNeural",
    Gender: "Female",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "141",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, GianniNeural)",
    DisplayName: "Gianni",
    LocalName: "Gianni",
    ShortName: "it-IT-GianniNeural",
    Gender: "Male",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "139",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, GiuseppeNeural)",
    DisplayName: "Giuseppe",
    LocalName: "Giuseppe",
    ShortName: "it-IT-GiuseppeNeural",
    Gender: "Male",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, ImeldaNeural)",
    DisplayName: "Imelda",
    LocalName: "Imelda",
    ShortName: "it-IT-ImeldaNeural",
    Gender: "Female",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "140",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, IrmaNeural)",
    DisplayName: "Irma",
    LocalName: "Irma",
    ShortName: "it-IT-IrmaNeural",
    Gender: "Female",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "142",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, LisandroNeural)",
    DisplayName: "Lisandro",
    LocalName: "Lisandro",
    ShortName: "it-IT-LisandroNeural",
    Gender: "Male",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "137",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, PalmiraNeural)",
    DisplayName: "Palmira",
    LocalName: "Palmira",
    ShortName: "it-IT-PalmiraNeural",
    Gender: "Female",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "139",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, PierinaNeural)",
    DisplayName: "Pierina",
    LocalName: "Pierina",
    ShortName: "it-IT-PierinaNeural",
    Gender: "Female",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "134",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, RinaldoNeural)",
    DisplayName: "Rinaldo",
    LocalName: "Rinaldo",
    ShortName: "it-IT-RinaldoNeural",
    Gender: "Male",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "137",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, AlessioMultilingualNeural)",
    DisplayName: "Alessio Multilingual",
    LocalName: "Alessio Multilingual",
    ShortName: "it-IT-AlessioMultilingualNeural",
    Gender: "Male",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, IsabellaMultilingualNeural)",
    DisplayName: "Isabella Multilingual",
    LocalName: "Isabella Multilingual",
    ShortName: "it-IT-IsabellaMultilingualNeural",
    Gender: "Female",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (it-IT, MarcelloMultilingualNeural)",
    DisplayName: "Marcello Multilingual",
    LocalName: "Marcello Multilingual",
    ShortName: "it-IT-MarcelloMultilingualNeural",
    Gender: "Male",
    Locale: "it-IT",
    LocaleName: "Italian (Italy)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ja-JP, NanamiNeural)",
    DisplayName: "Nanami",
    LocalName: "七海",
    ShortName: "ja-JP-NanamiNeural",
    Gender: "Female",
    Locale: "ja-JP",
    LocaleName: "Japanese (Japan)",
    StyleList: ["chat", "customerservice", "cheerful"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "305",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ja-JP, KeitaNeural)",
    DisplayName: "Keita",
    LocalName: "圭太",
    ShortName: "ja-JP-KeitaNeural",
    Gender: "Male",
    Locale: "ja-JP",
    LocaleName: "Japanese (Japan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "337",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ja-JP, AoiNeural)",
    DisplayName: "Aoi",
    LocalName: "碧衣",
    ShortName: "ja-JP-AoiNeural",
    Gender: "Female",
    Locale: "ja-JP",
    LocaleName: "Japanese (Japan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "270",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ja-JP, DaichiNeural)",
    DisplayName: "Daichi",
    LocalName: "大智",
    ShortName: "ja-JP-DaichiNeural",
    Gender: "Male",
    Locale: "ja-JP",
    LocaleName: "Japanese (Japan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "312",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ja-JP, MayuNeural)",
    DisplayName: "Mayu",
    LocalName: "真夕",
    ShortName: "ja-JP-MayuNeural",
    Gender: "Female",
    Locale: "ja-JP",
    LocaleName: "Japanese (Japan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "302",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ja-JP, NaokiNeural)",
    DisplayName: "Naoki",
    LocalName: "直紀",
    ShortName: "ja-JP-NaokiNeural",
    Gender: "Male",
    Locale: "ja-JP",
    LocaleName: "Japanese (Japan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "312",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ja-JP, ShioriNeural)",
    DisplayName: "Shiori",
    LocalName: "志織",
    ShortName: "ja-JP-ShioriNeural",
    Gender: "Female",
    Locale: "ja-JP",
    LocaleName: "Japanese (Japan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "296",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ja-JP, MasaruMultilingualNeural)",
    DisplayName: "Masaru Multilingual",
    LocalName: "勝 多言語",
    ShortName: "ja-JP-MasaruMultilingualNeural",
    Gender: "Male",
    Locale: "ja-JP",
    LocaleName: "Japanese (Japan)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "Preview",
    WordsPerMinute: "190",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (jv-ID, SitiNeural)",
    DisplayName: "Siti",
    LocalName: "Siti",
    ShortName: "jv-ID-SitiNeural",
    Gender: "Female",
    Locale: "jv-ID",
    LocaleName: "Javanese (Latin, Indonesia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "104",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (jv-ID, DimasNeural)",
    DisplayName: "Dimas",
    LocalName: "Dimas",
    ShortName: "jv-ID-DimasNeural",
    Gender: "Male",
    Locale: "jv-ID",
    LocaleName: "Javanese (Latin, Indonesia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "115",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ka-GE, EkaNeural)",
    DisplayName: "Eka",
    LocalName: "ეკა",
    ShortName: "ka-GE-EkaNeural",
    Gender: "Female",
    Locale: "ka-GE",
    LocaleName: "Georgian (Georgia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "104",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ka-GE, GiorgiNeural)",
    DisplayName: "Giorgi",
    LocalName: "გიორგი",
    ShortName: "ka-GE-GiorgiNeural",
    Gender: "Male",
    Locale: "ka-GE",
    LocaleName: "Georgian (Georgia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "104",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (kk-KZ, AigulNeural)",
    DisplayName: "Aigul",
    LocalName: "Айгүл",
    ShortName: "kk-KZ-AigulNeural",
    Gender: "Female",
    Locale: "kk-KZ",
    LocaleName: "Kazakh (Kazakhstan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "107",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (kk-KZ, DauletNeural)",
    DisplayName: "Daulet",
    LocalName: "Дәулет",
    ShortName: "kk-KZ-DauletNeural",
    Gender: "Male",
    Locale: "kk-KZ",
    LocaleName: "Kazakh (Kazakhstan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "111",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (km-KH, SreymomNeural)",
    DisplayName: "Sreymom",
    LocalName: "ស្រីមុំ",
    ShortName: "km-KH-SreymomNeural",
    Gender: "Female",
    Locale: "km-KH",
    LocaleName: "Khmer (Cambodia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "25",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (km-KH, PisethNeural)",
    DisplayName: "Piseth",
    LocalName: "ពិសិដ្ឋ",
    ShortName: "km-KH-PisethNeural",
    Gender: "Male",
    Locale: "km-KH",
    LocaleName: "Khmer (Cambodia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "25",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (kn-IN, SapnaNeural)",
    DisplayName: "Sapna",
    LocalName: "ಸಪ್ನಾ",
    ShortName: "kn-IN-SapnaNeural",
    Gender: "Female",
    Locale: "kn-IN",
    LocaleName: "Kannada (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "94",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (kn-IN, GaganNeural)",
    DisplayName: "Gagan",
    LocalName: "ಗಗನ್",
    ShortName: "kn-IN-GaganNeural",
    Gender: "Male",
    Locale: "kn-IN",
    LocaleName: "Kannada (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "100",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ko-KR, SunHiNeural)",
    DisplayName: "Sun-Hi",
    LocalName: "선히",
    ShortName: "ko-KR-SunHiNeural",
    Gender: "Female",
    Locale: "ko-KR",
    LocaleName: "Korean (Korea)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "274",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ko-KR, InJoonNeural)",
    DisplayName: "InJoon",
    LocalName: "인준",
    ShortName: "ko-KR-InJoonNeural",
    Gender: "Male",
    Locale: "ko-KR",
    LocaleName: "Korean (Korea)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "253",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ko-KR, BongJinNeural)",
    DisplayName: "BongJin",
    LocalName: "봉진",
    ShortName: "ko-KR-BongJinNeural",
    Gender: "Male",
    Locale: "ko-KR",
    LocaleName: "Korean (Korea)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "262",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ko-KR, GookMinNeural)",
    DisplayName: "GookMin",
    LocalName: "국민",
    ShortName: "ko-KR-GookMinNeural",
    Gender: "Male",
    Locale: "ko-KR",
    LocaleName: "Korean (Korea)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "278",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ko-KR, HyunsuNeural)",
    DisplayName: "Hyunsu",
    LocalName: "현수",
    ShortName: "ko-KR-HyunsuNeural",
    Gender: "Male",
    Locale: "ko-KR",
    LocaleName: "Korean (Korea)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ko-KR, JiMinNeural)",
    DisplayName: "JiMin",
    LocalName: "지민",
    ShortName: "ko-KR-JiMinNeural",
    Gender: "Female",
    Locale: "ko-KR",
    LocaleName: "Korean (Korea)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "291",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ko-KR, SeoHyeonNeural)",
    DisplayName: "SeoHyeon",
    LocalName: "서현",
    ShortName: "ko-KR-SeoHyeonNeural",
    Gender: "Female",
    Locale: "ko-KR",
    LocaleName: "Korean (Korea)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "258",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ko-KR, SoonBokNeural)",
    DisplayName: "SoonBok",
    LocalName: "순복",
    ShortName: "ko-KR-SoonBokNeural",
    Gender: "Female",
    Locale: "ko-KR",
    LocaleName: "Korean (Korea)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "271",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ko-KR, YuJinNeural)",
    DisplayName: "YuJin",
    LocalName: "유진",
    ShortName: "ko-KR-YuJinNeural",
    Gender: "Female",
    Locale: "ko-KR",
    LocaleName: "Korean (Korea)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "288",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (lo-LA, KeomanyNeural)",
    DisplayName: "Keomany",
    LocalName: "ແກ້ວມະນີ",
    ShortName: "lo-LA-KeomanyNeural",
    Gender: "Female",
    Locale: "lo-LA",
    LocaleName: "Lao (Laos)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "33",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (lo-LA, ChanthavongNeural)",
    DisplayName: "Chanthavong",
    LocalName: "ຈັນທະວົງ",
    ShortName: "lo-LA-ChanthavongNeural",
    Gender: "Male",
    Locale: "lo-LA",
    LocaleName: "Lao (Laos)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "35",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (lt-LT, OnaNeural)",
    DisplayName: "Ona",
    LocalName: "Ona",
    ShortName: "lt-LT-OnaNeural",
    Gender: "Female",
    Locale: "lt-LT",
    LocaleName: "Lithuanian (Lithuania)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "107",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (lt-LT, LeonasNeural)",
    DisplayName: "Leonas",
    LocalName: "Leonas",
    ShortName: "lt-LT-LeonasNeural",
    Gender: "Male",
    Locale: "lt-LT",
    LocaleName: "Lithuanian (Lithuania)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "111",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (lv-LV, EveritaNeural)",
    DisplayName: "Everita",
    LocalName: "Everita",
    ShortName: "lv-LV-EveritaNeural",
    Gender: "Female",
    Locale: "lv-LV",
    LocaleName: "Latvian (Latvia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "106",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (lv-LV, NilsNeural)",
    DisplayName: "Nils",
    LocalName: "Nils",
    ShortName: "lv-LV-NilsNeural",
    Gender: "Male",
    Locale: "lv-LV",
    LocaleName: "Latvian (Latvia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "120",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (mk-MK, MarijaNeural)",
    DisplayName: "Marija",
    LocalName: "Марија",
    ShortName: "mk-MK-MarijaNeural",
    Gender: "Female",
    Locale: "mk-MK",
    LocaleName: "Macedonian (North Macedonia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "127",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (mk-MK, AleksandarNeural)",
    DisplayName: "Aleksandar",
    LocalName: "Александар",
    ShortName: "mk-MK-AleksandarNeural",
    Gender: "Male",
    Locale: "mk-MK",
    LocaleName: "Macedonian (North Macedonia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "127",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ml-IN, SobhanaNeural)",
    DisplayName: "Sobhana",
    LocalName: "ശോഭന",
    ShortName: "ml-IN-SobhanaNeural",
    Gender: "Female",
    Locale: "ml-IN",
    LocaleName: "Malayalam (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "87",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ml-IN, MidhunNeural)",
    DisplayName: "Midhun",
    LocalName: "മിഥുൻ",
    ShortName: "ml-IN-MidhunNeural",
    Gender: "Male",
    Locale: "ml-IN",
    LocaleName: "Malayalam (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "93",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (mn-MN, YesuiNeural)",
    DisplayName: "Yesui",
    LocalName: "Есүй",
    ShortName: "mn-MN-YesuiNeural",
    Gender: "Female",
    Locale: "mn-MN",
    LocaleName: "Mongolian (Mongolia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "142",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (mn-MN, BataaNeural)",
    DisplayName: "Bataa",
    LocalName: "Батаа",
    ShortName: "mn-MN-BataaNeural",
    Gender: "Male",
    Locale: "mn-MN",
    LocaleName: "Mongolian (Mongolia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "142",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (mr-IN, AarohiNeural)",
    DisplayName: "Aarohi",
    LocalName: "आरोही",
    ShortName: "mr-IN-AarohiNeural",
    Gender: "Female",
    Locale: "mr-IN",
    LocaleName: "Marathi (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "99",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (mr-IN, ManoharNeural)",
    DisplayName: "Manohar",
    LocalName: "मनोहर",
    ShortName: "mr-IN-ManoharNeural",
    Gender: "Male",
    Locale: "mr-IN",
    LocaleName: "Marathi (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "100",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ms-MY, YasminNeural)",
    DisplayName: "Yasmin",
    LocalName: "Yasmin",
    ShortName: "ms-MY-YasminNeural",
    Gender: "Female",
    Locale: "ms-MY",
    LocaleName: "Malay (Malaysia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "112",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ms-MY, OsmanNeural)",
    DisplayName: "Osman",
    LocalName: "Osman",
    ShortName: "ms-MY-OsmanNeural",
    Gender: "Male",
    Locale: "ms-MY",
    LocaleName: "Malay (Malaysia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "118",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (mt-MT, GraceNeural)",
    DisplayName: "Grace",
    LocalName: "Grace",
    ShortName: "mt-MT-GraceNeural",
    Gender: "Female",
    Locale: "mt-MT",
    LocaleName: "Maltese (Malta)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "136",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (mt-MT, JosephNeural)",
    DisplayName: "Joseph",
    LocalName: "Joseph",
    ShortName: "mt-MT-JosephNeural",
    Gender: "Male",
    Locale: "mt-MT",
    LocaleName: "Maltese (Malta)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "130",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (my-MM, NilarNeural)",
    DisplayName: "Nilar",
    LocalName: "နီလာ",
    ShortName: "my-MM-NilarNeural",
    Gender: "Female",
    Locale: "my-MM",
    LocaleName: "Burmese (Myanmar)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "63",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (my-MM, ThihaNeural)",
    DisplayName: "Thiha",
    LocalName: "သီဟ",
    ShortName: "my-MM-ThihaNeural",
    Gender: "Male",
    Locale: "my-MM",
    LocaleName: "Burmese (Myanmar)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "71",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (nb-NO, PernilleNeural)",
    DisplayName: "Pernille",
    LocalName: "Pernille",
    ShortName: "nb-NO-PernilleNeural",
    Gender: "Female",
    Locale: "nb-NO",
    LocaleName: "Norwegian Bokmål (Norway)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "160",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (nb-NO, FinnNeural)",
    DisplayName: "Finn",
    LocalName: "Finn",
    ShortName: "nb-NO-FinnNeural",
    Gender: "Male",
    Locale: "nb-NO",
    LocaleName: "Norwegian Bokmål (Norway)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "145",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (nb-NO, IselinNeural)",
    DisplayName: "Iselin",
    LocalName: "Iselin",
    ShortName: "nb-NO-IselinNeural",
    Gender: "Female",
    Locale: "nb-NO",
    LocaleName: "Norwegian Bokmål (Norway)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "154",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ne-NP, HemkalaNeural)",
    DisplayName: "Hemkala",
    LocalName: "हेमकला",
    ShortName: "ne-NP-HemkalaNeural",
    Gender: "Female",
    Locale: "ne-NP",
    LocaleName: "Nepali (Nepal)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "119",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ne-NP, SagarNeural)",
    DisplayName: "Sagar",
    LocalName: "सागर",
    ShortName: "ne-NP-SagarNeural",
    Gender: "Male",
    Locale: "ne-NP",
    LocaleName: "Nepali (Nepal)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "119",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (nl-BE, DenaNeural)",
    DisplayName: "Dena",
    LocalName: "Dena",
    ShortName: "nl-BE-DenaNeural",
    Gender: "Female",
    Locale: "nl-BE",
    LocaleName: "Dutch (Belgium)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "134",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (nl-BE, ArnaudNeural)",
    DisplayName: "Arnaud",
    LocalName: "Arnaud",
    ShortName: "nl-BE-ArnaudNeural",
    Gender: "Male",
    Locale: "nl-BE",
    LocaleName: "Dutch (Belgium)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "143",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (nl-NL, FennaNeural)",
    DisplayName: "Fenna",
    LocalName: "Fenna",
    ShortName: "nl-NL-FennaNeural",
    Gender: "Female",
    Locale: "nl-NL",
    LocaleName: "Dutch (Netherlands)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "140",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (nl-NL, MaartenNeural)",
    DisplayName: "Maarten",
    LocalName: "Maarten",
    ShortName: "nl-NL-MaartenNeural",
    Gender: "Male",
    Locale: "nl-NL",
    LocaleName: "Dutch (Netherlands)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "151",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (nl-NL, ColetteNeural)",
    DisplayName: "Colette",
    LocalName: "Colette",
    ShortName: "nl-NL-ColetteNeural",
    Gender: "Female",
    Locale: "nl-NL",
    LocaleName: "Dutch (Netherlands)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "132",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (or-IN, SubhasiniNeural)",
    DisplayName: "Subhasini",
    LocalName: "ସୁଭାସିନୀ",
    ShortName: "or-IN-SubhasiniNeural",
    Gender: "Female",
    Locale: "or-IN",
    LocaleName: "Oriya (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (or-IN, SukantNeural)",
    DisplayName: "Sukant",
    LocalName: "ସୁକାନ୍ତ",
    ShortName: "or-IN-SukantNeural",
    Gender: "Male",
    Locale: "or-IN",
    LocaleName: "Oriya (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pa-IN, OjasNeural)",
    DisplayName: "Ojas",
    LocalName: "ਓਜਸ",
    ShortName: "pa-IN-OjasNeural",
    Gender: "Male",
    Locale: "pa-IN",
    LocaleName: "Punjabi (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pa-IN, VaaniNeural)",
    DisplayName: "Vaani",
    LocalName: "ਵਾਨੀ",
    ShortName: "pa-IN-VaaniNeural",
    Gender: "Female",
    Locale: "pa-IN",
    LocaleName: "Punjabi (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pl-PL, AgnieszkaNeural)",
    DisplayName: "Agnieszka",
    LocalName: "Agnieszka",
    ShortName: "pl-PL-AgnieszkaNeural",
    Gender: "Female",
    Locale: "pl-PL",
    LocaleName: "Polish (Poland)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "112",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pl-PL, MarekNeural)",
    DisplayName: "Marek",
    LocalName: "Marek",
    ShortName: "pl-PL-MarekNeural",
    Gender: "Male",
    Locale: "pl-PL",
    LocaleName: "Polish (Poland)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "128",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pl-PL, ZofiaNeural)",
    DisplayName: "Zofia",
    LocalName: "Zofia",
    ShortName: "pl-PL-ZofiaNeural",
    Gender: "Female",
    Locale: "pl-PL",
    LocaleName: "Polish (Poland)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "127",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ps-AF, LatifaNeural)",
    DisplayName: "Latifa",
    LocalName: "لطيفه",
    ShortName: "ps-AF-LatifaNeural",
    Gender: "Female",
    Locale: "ps-AF",
    LocaleName: "Pashto (Afghanistan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "165",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ps-AF, GulNawazNeural)",
    DisplayName: "Gul Nawaz",
    LocalName: " ګل نواز",
    ShortName: "ps-AF-GulNawazNeural",
    Gender: "Male",
    Locale: "ps-AF",
    LocaleName: "Pashto (Afghanistan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "170",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, FranciscaNeural)",
    DisplayName: "Francisca",
    LocalName: "Francisca",
    ShortName: "pt-BR-FranciscaNeural",
    Gender: "Female",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    StyleList: ["calm"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "134",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, AntonioNeural)",
    DisplayName: "Antonio",
    LocalName: "Antônio",
    ShortName: "pt-BR-AntonioNeural",
    Gender: "Male",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "138",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, BrendaNeural)",
    DisplayName: "Brenda",
    LocalName: "Brenda",
    ShortName: "pt-BR-BrendaNeural",
    Gender: "Female",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "144",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, DonatoNeural)",
    DisplayName: "Donato",
    LocalName: "Donato",
    ShortName: "pt-BR-DonatoNeural",
    Gender: "Male",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "152",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, ElzaNeural)",
    DisplayName: "Elza",
    LocalName: "Elza",
    ShortName: "pt-BR-ElzaNeural",
    Gender: "Female",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "135",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, FabioNeural)",
    DisplayName: "Fabio",
    LocalName: "Fabio",
    ShortName: "pt-BR-FabioNeural",
    Gender: "Male",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "134",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, GiovannaNeural)",
    DisplayName: "Giovanna",
    LocalName: "Giovanna",
    ShortName: "pt-BR-GiovannaNeural",
    Gender: "Female",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "143",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, HumbertoNeural)",
    DisplayName: "Humberto",
    LocalName: "Humberto",
    ShortName: "pt-BR-HumbertoNeural",
    Gender: "Male",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "146",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, JulioNeural)",
    DisplayName: "Julio",
    LocalName: "Julio",
    ShortName: "pt-BR-JulioNeural",
    Gender: "Male",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "136",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, LeilaNeural)",
    DisplayName: "Leila",
    LocalName: "Leila",
    ShortName: "pt-BR-LeilaNeural",
    Gender: "Female",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "153",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, LeticiaNeural)",
    DisplayName: "Leticia",
    LocalName: "Leticia",
    ShortName: "pt-BR-LeticiaNeural",
    Gender: "Female",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "128",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, ManuelaNeural)",
    DisplayName: "Manuela",
    LocalName: "Manuela",
    ShortName: "pt-BR-ManuelaNeural",
    Gender: "Female",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "135",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, NicolauNeural)",
    DisplayName: "Nicolau",
    LocalName: "Nicolau",
    ShortName: "pt-BR-NicolauNeural",
    Gender: "Male",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "132",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, ThalitaMultilingualNeural)",
    DisplayName: "Thalita Multilingual",
    LocalName: "Thalita multilíngue",
    ShortName: "pt-BR-ThalitaMultilingualNeural",
    Gender: "Female",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "GA",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, ThalitaNeural)",
    DisplayName: "Thalita",
    LocalName: "Thalita",
    ShortName: "pt-BR-ThalitaNeural",
    Gender: "Female",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, ValerioNeural)",
    DisplayName: "Valerio",
    LocalName: "Valerio",
    ShortName: "pt-BR-ValerioNeural",
    Gender: "Male",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "131",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-BR, YaraNeural)",
    DisplayName: "Yara",
    LocalName: "Yara",
    ShortName: "pt-BR-YaraNeural",
    Gender: "Female",
    Locale: "pt-BR",
    LocaleName: "Portuguese (Brazil)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "136",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-PT, RaquelNeural)",
    DisplayName: "Raquel",
    LocalName: "Raquel",
    ShortName: "pt-PT-RaquelNeural",
    Gender: "Female",
    Locale: "pt-PT",
    LocaleName: "Portuguese (Portugal)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "144",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-PT, DuarteNeural)",
    DisplayName: "Duarte",
    LocalName: "Duarte",
    ShortName: "pt-PT-DuarteNeural",
    Gender: "Male",
    Locale: "pt-PT",
    LocaleName: "Portuguese (Portugal)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "182",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (pt-PT, FernandaNeural)",
    DisplayName: "Fernanda",
    LocalName: "Fernanda",
    ShortName: "pt-PT-FernandaNeural",
    Gender: "Female",
    Locale: "pt-PT",
    LocaleName: "Portuguese (Portugal)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "166",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ro-RO, AlinaNeural)",
    DisplayName: "Alina",
    LocalName: "Alina",
    ShortName: "ro-RO-AlinaNeural",
    Gender: "Female",
    Locale: "ro-RO",
    LocaleName: "Romanian (Romania)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "141",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ro-RO, EmilNeural)",
    DisplayName: "Emil",
    LocalName: "Emil",
    ShortName: "ro-RO-EmilNeural",
    Gender: "Male",
    Locale: "ro-RO",
    LocaleName: "Romanian (Romania)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "144",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ru-RU, SvetlanaNeural)",
    DisplayName: "Svetlana",
    LocalName: "Светлана",
    ShortName: "ru-RU-SvetlanaNeural",
    Gender: "Female",
    Locale: "ru-RU",
    LocaleName: "Russian (Russia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "113",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ru-RU, DmitryNeural)",
    DisplayName: "Dmitry",
    LocalName: "Дмитрий",
    ShortName: "ru-RU-DmitryNeural",
    Gender: "Male",
    Locale: "ru-RU",
    LocaleName: "Russian (Russia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "113",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ru-RU, DariyaNeural)",
    DisplayName: "Dariya",
    LocalName: "Дария",
    ShortName: "ru-RU-DariyaNeural",
    Gender: "Female",
    Locale: "ru-RU",
    LocaleName: "Russian (Russia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "113",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (si-LK, ThiliniNeural)",
    DisplayName: "Thilini",
    LocalName: "තිළිණි",
    ShortName: "si-LK-ThiliniNeural",
    Gender: "Female",
    Locale: "si-LK",
    LocaleName: "Sinhala (Sri Lanka)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "142",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (si-LK, SameeraNeural)",
    DisplayName: "Sameera",
    LocalName: "සමීර",
    ShortName: "si-LK-SameeraNeural",
    Gender: "Male",
    Locale: "si-LK",
    LocaleName: "Sinhala (Sri Lanka)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "155",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sk-SK, ViktoriaNeural)",
    DisplayName: "Viktoria",
    LocalName: "Viktória",
    ShortName: "sk-SK-ViktoriaNeural",
    Gender: "Female",
    Locale: "sk-SK",
    LocaleName: "Slovak (Slovakia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "118",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sk-SK, LukasNeural)",
    DisplayName: "Lukas",
    LocalName: "Lukáš",
    ShortName: "sk-SK-LukasNeural",
    Gender: "Male",
    Locale: "sk-SK",
    LocaleName: "Slovak (Slovakia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "132",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sl-SI, PetraNeural)",
    DisplayName: "Petra",
    LocalName: "Petra",
    ShortName: "sl-SI-PetraNeural",
    Gender: "Female",
    Locale: "sl-SI",
    LocaleName: "Slovenian (Slovenia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "138",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sl-SI, RokNeural)",
    DisplayName: "Rok",
    LocalName: "Rok",
    ShortName: "sl-SI-RokNeural",
    Gender: "Male",
    Locale: "sl-SI",
    LocaleName: "Slovenian (Slovenia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "126",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (so-SO, UbaxNeural)",
    DisplayName: "Ubax",
    LocalName: "Ubax",
    ShortName: "so-SO-UbaxNeural",
    Gender: "Female",
    Locale: "so-SO",
    LocaleName: "Somali (Somalia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "126",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (so-SO, MuuseNeural)",
    DisplayName: "Muuse",
    LocalName: "Muuse",
    ShortName: "so-SO-MuuseNeural",
    Gender: "Male",
    Locale: "so-SO",
    LocaleName: "Somali (Somalia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "136",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sq-AL, AnilaNeural)",
    DisplayName: "Anila",
    LocalName: "Anila",
    ShortName: "sq-AL-AnilaNeural",
    Gender: "Female",
    Locale: "sq-AL",
    LocaleName: "Albanian (Albania)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "141",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sq-AL, IlirNeural)",
    DisplayName: "Ilir",
    LocalName: "Ilir",
    ShortName: "sq-AL-IlirNeural",
    Gender: "Male",
    Locale: "sq-AL",
    LocaleName: "Albanian (Albania)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "141",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sr-Latn-RS, NicholasNeural)",
    DisplayName: "Nicholas",
    LocalName: "Nicholas",
    ShortName: "sr-Latn-RS-NicholasNeural",
    Gender: "Male",
    Locale: "sr-Latn-RS",
    LocaleName: "Serbian (Latin, Serbia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sr-Latn-RS, SophieNeural)",
    DisplayName: "Sophie",
    LocalName: "Sophie",
    ShortName: "sr-Latn-RS-SophieNeural",
    Gender: "Female",
    Locale: "sr-Latn-RS",
    LocaleName: "Serbian (Latin, Serbia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sr-RS, SophieNeural)",
    DisplayName: "Sophie",
    LocalName: "Софија",
    ShortName: "sr-RS-SophieNeural",
    Gender: "Female",
    Locale: "sr-RS",
    LocaleName: "Serbian (Cyrillic, Serbia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "132",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sr-RS, NicholasNeural)",
    DisplayName: "Nicholas",
    LocalName: "Никола",
    ShortName: "sr-RS-NicholasNeural",
    Gender: "Male",
    Locale: "sr-RS",
    LocaleName: "Serbian (Cyrillic, Serbia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "128",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (su-ID, TutiNeural)",
    DisplayName: "Tuti",
    LocalName: "Tuti",
    ShortName: "su-ID-TutiNeural",
    Gender: "Female",
    Locale: "su-ID",
    LocaleName: "Sundanese (Indonesia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "111",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (su-ID, JajangNeural)",
    DisplayName: "Jajang",
    LocalName: "Jajang",
    ShortName: "su-ID-JajangNeural",
    Gender: "Male",
    Locale: "su-ID",
    LocaleName: "Sundanese (Indonesia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "115",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sv-SE, SofieNeural)",
    DisplayName: "Sofie",
    LocalName: "Sofie",
    ShortName: "sv-SE-SofieNeural",
    Gender: "Female",
    Locale: "sv-SE",
    LocaleName: "Swedish (Sweden)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "138",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sv-SE, MattiasNeural)",
    DisplayName: "Mattias",
    LocalName: "Mattias",
    ShortName: "sv-SE-MattiasNeural",
    Gender: "Male",
    Locale: "sv-SE",
    LocaleName: "Swedish (Sweden)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "135",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sv-SE, HilleviNeural)",
    DisplayName: "Hillevi",
    LocalName: "Hillevi",
    ShortName: "sv-SE-HilleviNeural",
    Gender: "Female",
    Locale: "sv-SE",
    LocaleName: "Swedish (Sweden)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "147",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sw-KE, ZuriNeural)",
    DisplayName: "Zuri",
    LocalName: "Zuri",
    ShortName: "sw-KE-ZuriNeural",
    Gender: "Female",
    Locale: "sw-KE",
    LocaleName: "Swahili (Kenya)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "113",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sw-KE, RafikiNeural)",
    DisplayName: "Rafiki",
    LocalName: "Rafiki",
    ShortName: "sw-KE-RafikiNeural",
    Gender: "Male",
    Locale: "sw-KE",
    LocaleName: "Swahili (Kenya)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "121",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sw-TZ, RehemaNeural)",
    DisplayName: "Rehema",
    LocalName: "Rehema",
    ShortName: "sw-TZ-RehemaNeural",
    Gender: "Female",
    Locale: "sw-TZ",
    LocaleName: "Swahili (Tanzania)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "108",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (sw-TZ, DaudiNeural)",
    DisplayName: "Daudi",
    LocalName: "Daudi",
    ShortName: "sw-TZ-DaudiNeural",
    Gender: "Male",
    Locale: "sw-TZ",
    LocaleName: "Swahili (Tanzania)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "114",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ta-IN, PallaviNeural)",
    DisplayName: "Pallavi",
    LocalName: "பல்லவி",
    ShortName: "ta-IN-PallaviNeural",
    Gender: "Female",
    Locale: "ta-IN",
    LocaleName: "Tamil (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "79",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ta-IN, ValluvarNeural)",
    DisplayName: "Valluvar",
    LocalName: "வள்ளுவர்",
    ShortName: "ta-IN-ValluvarNeural",
    Gender: "Male",
    Locale: "ta-IN",
    LocaleName: "Tamil (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "98",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ta-LK, SaranyaNeural)",
    DisplayName: "Saranya",
    LocalName: "சரண்யா",
    ShortName: "ta-LK-SaranyaNeural",
    Gender: "Female",
    Locale: "ta-LK",
    LocaleName: "Tamil (Sri Lanka)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "75",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ta-LK, KumarNeural)",
    DisplayName: "Kumar",
    LocalName: "குமார்",
    ShortName: "ta-LK-KumarNeural",
    Gender: "Male",
    Locale: "ta-LK",
    LocaleName: "Tamil (Sri Lanka)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "93",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ta-MY, KaniNeural)",
    DisplayName: "Kani",
    LocalName: "கனி",
    ShortName: "ta-MY-KaniNeural",
    Gender: "Female",
    Locale: "ta-MY",
    LocaleName: "Tamil (Malaysia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "83",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ta-MY, SuryaNeural)",
    DisplayName: "Surya",
    LocalName: "சூர்யா",
    ShortName: "ta-MY-SuryaNeural",
    Gender: "Male",
    Locale: "ta-MY",
    LocaleName: "Tamil (Malaysia)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "93",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ta-SG, VenbaNeural)",
    DisplayName: "Venba",
    LocalName: "வெண்பா",
    ShortName: "ta-SG-VenbaNeural",
    Gender: "Female",
    Locale: "ta-SG",
    LocaleName: "Tamil (Singapore)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "83",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ta-SG, AnbuNeural)",
    DisplayName: "Anbu",
    LocalName: "அன்பு",
    ShortName: "ta-SG-AnbuNeural",
    Gender: "Male",
    Locale: "ta-SG",
    LocaleName: "Tamil (Singapore)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "103",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (te-IN, ShrutiNeural)",
    DisplayName: "Shruti",
    LocalName: "శ్రుతి",
    ShortName: "te-IN-ShrutiNeural",
    Gender: "Female",
    Locale: "te-IN",
    LocaleName: "Telugu (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "79",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (te-IN, MohanNeural)",
    DisplayName: "Mohan",
    LocalName: "మోహన్",
    ShortName: "te-IN-MohanNeural",
    Gender: "Male",
    Locale: "te-IN",
    LocaleName: "Telugu (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "103",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (th-TH, PremwadeeNeural)",
    DisplayName: "Premwadee",
    LocalName: "เปรมวดี",
    ShortName: "th-TH-PremwadeeNeural",
    Gender: "Female",
    Locale: "th-TH",
    LocaleName: "Thai (Thailand)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "49",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (th-TH, NiwatNeural)",
    DisplayName: "Niwat",
    LocalName: "นิวัฒน์",
    ShortName: "th-TH-NiwatNeural",
    Gender: "Male",
    Locale: "th-TH",
    LocaleName: "Thai (Thailand)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "49",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (th-TH, AcharaNeural)",
    DisplayName: "Achara",
    LocalName: "อัจฉรา",
    ShortName: "th-TH-AcharaNeural",
    Gender: "Female",
    Locale: "th-TH",
    LocaleName: "Thai (Thailand)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "51",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (tr-TR, EmelNeural)",
    DisplayName: "Emel",
    LocalName: "Emel",
    ShortName: "tr-TR-EmelNeural",
    Gender: "Female",
    Locale: "tr-TR",
    LocaleName: "Turkish (Turkey)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "111",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (tr-TR, AhmetNeural)",
    DisplayName: "Ahmet",
    LocalName: "Ahmet",
    ShortName: "tr-TR-AhmetNeural",
    Gender: "Male",
    Locale: "tr-TR",
    LocaleName: "Turkish (Turkey)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "108",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (uk-UA, PolinaNeural)",
    DisplayName: "Polina",
    LocalName: "Поліна",
    ShortName: "uk-UA-PolinaNeural",
    Gender: "Female",
    Locale: "uk-UA",
    LocaleName: "Ukrainian (Ukraine)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "111",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (uk-UA, OstapNeural)",
    DisplayName: "Ostap",
    LocalName: "Остап",
    ShortName: "uk-UA-OstapNeural",
    Gender: "Male",
    Locale: "uk-UA",
    LocaleName: "Ukrainian (Ukraine)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "109",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ur-IN, GulNeural)",
    DisplayName: "Gul",
    LocalName: "گل",
    ShortName: "ur-IN-GulNeural",
    Gender: "Female",
    Locale: "ur-IN",
    LocaleName: "Urdu (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "157",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ur-IN, SalmanNeural)",
    DisplayName: "Salman",
    LocalName: "سلمان",
    ShortName: "ur-IN-SalmanNeural",
    Gender: "Male",
    Locale: "ur-IN",
    LocaleName: "Urdu (India)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "103",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ur-PK, UzmaNeural)",
    DisplayName: "Uzma",
    LocalName: "عظمیٰ",
    ShortName: "ur-PK-UzmaNeural",
    Gender: "Female",
    Locale: "ur-PK",
    LocaleName: "Urdu (Pakistan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "168",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (ur-PK, AsadNeural)",
    DisplayName: "Asad",
    LocalName: "اسد",
    ShortName: "ur-PK-AsadNeural",
    Gender: "Male",
    Locale: "ur-PK",
    LocaleName: "Urdu (Pakistan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "167",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (uz-UZ, MadinaNeural)",
    DisplayName: "Madina",
    LocalName: "Madina",
    ShortName: "uz-UZ-MadinaNeural",
    Gender: "Female",
    Locale: "uz-UZ",
    LocaleName: "Uzbek (Latin, Uzbekistan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "105",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (uz-UZ, SardorNeural)",
    DisplayName: "Sardor",
    LocalName: "Sardor",
    ShortName: "uz-UZ-SardorNeural",
    Gender: "Male",
    Locale: "uz-UZ",
    LocaleName: "Uzbek (Latin, Uzbekistan)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "112",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (vi-VN, HoaiMyNeural)",
    DisplayName: "HoaiMy",
    LocalName: "Hoài My",
    ShortName: "vi-VN-HoaiMyNeural",
    Gender: "Female",
    Locale: "vi-VN",
    LocaleName: "Vietnamese (Vietnam)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "202",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (vi-VN, NamMinhNeural)",
    DisplayName: "NamMinh",
    LocalName: "Nam Minh",
    ShortName: "vi-VN-NamMinhNeural",
    Gender: "Male",
    Locale: "vi-VN",
    LocaleName: "Vietnamese (Vietnam)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "204",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (wuu-CN, XiaotongNeural)",
    DisplayName: "Xiaotong",
    LocalName: "晓彤",
    ShortName: "wuu-CN-XiaotongNeural",
    Gender: "Female",
    Locale: "wuu-CN",
    LocaleName: "Chinese (Wu, Simplified)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "238",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (wuu-CN, YunzheNeural)",
    DisplayName: "Yunzhe",
    LocalName: "云哲",
    ShortName: "wuu-CN-YunzheNeural",
    Gender: "Male",
    Locale: "wuu-CN",
    LocaleName: "Chinese (Wu, Simplified)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "244",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (yue-CN, XiaoMinNeural)",
    DisplayName: "XiaoMin",
    LocalName: "晓敏",
    ShortName: "yue-CN-XiaoMinNeural",
    Gender: "Female",
    Locale: "yue-CN",
    LocaleName: "Chinese (Cantonese, Simplified)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "214",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (yue-CN, YunSongNeural)",
    DisplayName: "YunSong",
    LocalName: "云松",
    ShortName: "yue-CN-YunSongNeural",
    Gender: "Male",
    Locale: "yue-CN",
    LocaleName: "Chinese (Cantonese, Simplified)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "221",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoxiaoNeural)",
    DisplayName: "Xiaoxiao",
    LocalName: "晓晓",
    ShortName: "zh-CN-XiaoxiaoNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: [
      "assistant",
      "chat",
      "customerservice",
      "newscast",
      "affectionate",
      "angry",
      "calm",
      "cheerful",
      "disgruntled",
      "fearful",
      "gentle",
      "lyrical",
      "sad",
      "serious",
      "poetry-reading",
      "friendly",
      "chat-casual",
      "whispering",
      "sorry",
      "excited",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "274",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunxiNeural)",
    DisplayName: "Yunxi",
    LocalName: "云希",
    ShortName: "zh-CN-YunxiNeural",
    Gender: "Male",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: [
      "narration-relaxed",
      "embarrassed",
      "fearful",
      "cheerful",
      "disgruntled",
      "serious",
      "angry",
      "sad",
      "depressed",
      "chat",
      "assistant",
      "newscast",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    RolePlayList: ["Narrator", "YoungAdultMale", "Boy"],
    WordsPerMinute: "293",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunjianNeural)",
    DisplayName: "Yunjian",
    LocalName: "云健",
    ShortName: "zh-CN-YunjianNeural",
    Gender: "Male",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: [
      "narration-relaxed",
      "sports-commentary",
      "sports-commentary-excited",
      "angry",
      "disgruntled",
      "cheerful",
      "sad",
      "serious",
      "depressed",
      "documentary-narration",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "279",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyiNeural)",
    DisplayName: "Xiaoyi",
    LocalName: "晓伊",
    ShortName: "zh-CN-XiaoyiNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: [
      "angry",
      "disgruntled",
      "affectionate",
      "cheerful",
      "fearful",
      "sad",
      "embarrassed",
      "serious",
      "gentle",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "263",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunyangNeural)",
    DisplayName: "Yunyang",
    LocalName: "云扬",
    ShortName: "zh-CN-YunyangNeural",
    Gender: "Male",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: ["customerservice", "narration-professional", "newscast-casual"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "293",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaochenNeural)",
    DisplayName: "Xiaochen",
    LocalName: "晓辰",
    ShortName: "zh-CN-XiaochenNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: ["livecommercial"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "283",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaochenMultilingualNeural)",
    DisplayName: "Xiaochen Multilingual",
    LocalName: "晓辰 多语言",
    ShortName: "zh-CN-XiaochenMultilingualNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "GA",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaohanNeural)",
    DisplayName: "Xiaohan",
    LocalName: "晓涵",
    ShortName: "zh-CN-XiaohanNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: [
      "calm",
      "fearful",
      "cheerful",
      "disgruntled",
      "serious",
      "angry",
      "sad",
      "gentle",
      "affectionate",
      "embarrassed",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "259",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaomengNeural)",
    DisplayName: "Xiaomeng",
    LocalName: "晓梦",
    ShortName: "zh-CN-XiaomengNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: ["chat"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "272",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaomoNeural)",
    DisplayName: "Xiaomo",
    LocalName: "晓墨",
    ShortName: "zh-CN-XiaomoNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: [
      "embarrassed",
      "calm",
      "fearful",
      "cheerful",
      "disgruntled",
      "serious",
      "angry",
      "sad",
      "depressed",
      "affectionate",
      "gentle",
      "envious",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    RolePlayList: [
      "YoungAdultFemale",
      "YoungAdultMale",
      "OlderAdultFemale",
      "OlderAdultMale",
      "SeniorFemale",
      "SeniorMale",
      "Girl",
      "Boy",
    ],
    WordsPerMinute: "286",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoqiuNeural)",
    DisplayName: "Xiaoqiu",
    LocalName: "晓秋",
    ShortName: "zh-CN-XiaoqiuNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "232",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaorouNeural)",
    DisplayName: "Xiaorou",
    LocalName: "晓柔",
    ShortName: "zh-CN-XiaorouNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoruiNeural)",
    DisplayName: "Xiaorui",
    LocalName: "晓睿",
    ShortName: "zh-CN-XiaoruiNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: ["calm", "fearful", "angry", "sad"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "243",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoshuangNeural)",
    DisplayName: "Xiaoshuang",
    LocalName: "晓双",
    ShortName: "zh-CN-XiaoshuangNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: ["chat"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "225",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoxiaoDialectsNeural)",
    DisplayName: "Xiaoxiao Dialects",
    LocalName: "晓晓 方言",
    ShortName: "zh-CN-XiaoxiaoDialectsNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    SecondaryLocaleList: [
      "zh-CN-shaanxi",
      "zh-CN-sichuan",
      "zh-CN-shanxi",
      "zh-CN-anhui",
      "zh-CN-hunan",
      "zh-CN-gansu",
      "zh-CN-shandong",
      "zh-CN-henan",
      "zh-CN-liaoning",
      "zh-TW",
      "nan-CN",
      "yue-CN",
      "wuu-CN",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "GA",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoxiaoMultilingualNeural)",
    DisplayName: "Xiaoxiao Multilingual",
    LocalName: "晓晓 多语言",
    ShortName: "zh-CN-XiaoxiaoMultilingualNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "GA",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyanNeural)",
    DisplayName: "Xiaoyan",
    LocalName: "晓颜",
    ShortName: "zh-CN-XiaoyanNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "279",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyouNeural)",
    DisplayName: "Xiaoyou",
    LocalName: "晓悠",
    ShortName: "zh-CN-XiaoyouNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "211",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyuMultilingualNeural)",
    DisplayName: "Xiaoyu Multilingual",
    LocalName: "晓宇 多语言",
    ShortName: "zh-CN-XiaoyuMultilingualNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "GA",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaozhenNeural)",
    DisplayName: "Xiaozhen",
    LocalName: "晓甄",
    ShortName: "zh-CN-XiaozhenNeural",
    Gender: "Female",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: [
      "angry",
      "disgruntled",
      "cheerful",
      "fearful",
      "sad",
      "serious",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "273",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunfengNeural)",
    DisplayName: "Yunfeng",
    LocalName: "云枫",
    ShortName: "zh-CN-YunfengNeural",
    Gender: "Male",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: [
      "angry",
      "disgruntled",
      "cheerful",
      "fearful",
      "sad",
      "serious",
      "depressed",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "320",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunhaoNeural)",
    DisplayName: "Yunhao",
    LocalName: "云皓",
    ShortName: "zh-CN-YunhaoNeural",
    Gender: "Male",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: ["advertisement-upbeat"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "315",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunjieNeural)",
    DisplayName: "Yunjie",
    LocalName: "云杰",
    ShortName: "zh-CN-YunjieNeural",
    Gender: "Male",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunxiaNeural)",
    DisplayName: "Yunxia",
    LocalName: "云夏",
    ShortName: "zh-CN-YunxiaNeural",
    Gender: "Male",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: ["calm", "fearful", "cheerful", "angry", "sad"],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "269",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunyeNeural)",
    DisplayName: "Yunye",
    LocalName: "云野",
    ShortName: "zh-CN-YunyeNeural",
    Gender: "Male",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: [
      "embarrassed",
      "calm",
      "fearful",
      "cheerful",
      "disgruntled",
      "serious",
      "angry",
      "sad",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    RolePlayList: [
      "YoungAdultFemale",
      "YoungAdultMale",
      "OlderAdultFemale",
      "OlderAdultMale",
      "SeniorFemale",
      "SeniorMale",
      "Girl",
      "Boy",
    ],
    WordsPerMinute: "278",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunyiMultilingualNeural)",
    DisplayName: "Yunyi Multilingual",
    LocalName: "云逸 多语言",
    ShortName: "zh-CN-YunyiMultilingualNeural",
    Gender: "Male",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    SecondaryLocaleList: [
      "af-ZA",
      "am-ET",
      "ar-EG",
      "ar-SA",
      "az-AZ",
      "bg-BG",
      "bn-BD",
      "bn-IN",
      "bs-BA",
      "ca-ES",
      "cs-CZ",
      "cy-GB",
      "da-DK",
      "de-AT",
      "de-CH",
      "de-DE",
      "el-GR",
      "en-AU",
      "en-CA",
      "en-GB",
      "en-IE",
      "en-IN",
      "en-US",
      "es-ES",
      "es-MX",
      "et-EE",
      "eu-ES",
      "fa-IR",
      "fi-FI",
      "fil-PH",
      "fr-BE",
      "fr-CA",
      "fr-CH",
      "fr-FR",
      "ga-IE",
      "gl-ES",
      "he-IL",
      "hi-IN",
      "hr-HR",
      "hu-HU",
      "hy-AM",
      "id-ID",
      "is-IS",
      "it-IT",
      "ja-JP",
      "jv-ID",
      "ka-GE",
      "kk-KZ",
      "km-KH",
      "kn-IN",
      "ko-KR",
      "lo-LA",
      "lt-LT",
      "lv-LV",
      "mk-MK",
      "ml-IN",
      "mn-MN",
      "ms-MY",
      "mt-MT",
      "my-MM",
      "nb-NO",
      "ne-NP",
      "nl-BE",
      "nl-NL",
      "pl-PL",
      "ps-AF",
      "pt-BR",
      "pt-PT",
      "ro-RO",
      "ru-RU",
      "si-LK",
      "sk-SK",
      "sl-SI",
      "so-SO",
      "sq-AL",
      "sr-RS",
      "su-ID",
      "sv-SE",
      "sw-KE",
      "ta-IN",
      "te-IN",
      "th-TH",
      "tr-TR",
      "uk-UA",
      "ur-PK",
      "uz-UZ",
      "vi-VN",
      "zh-CN",
      "zh-HK",
      "zh-TW",
      "zu-ZA",
    ],
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "GA",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN, YunzeNeural)",
    DisplayName: "Yunze",
    LocalName: "云泽",
    ShortName: "zh-CN-YunzeNeural",
    Gender: "Male",
    Locale: "zh-CN",
    LocaleName: "Chinese (Mandarin, Simplified)",
    StyleList: [
      "calm",
      "fearful",
      "cheerful",
      "disgruntled",
      "serious",
      "angry",
      "sad",
      "depressed",
      "documentary-narration",
    ],
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    RolePlayList: ["OlderAdultMale", "SeniorMale"],
    WordsPerMinute: "255",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN-guangxi, YunqiNeural)",
    DisplayName: "Yunqi",
    LocalName: "云奇 广西",
    ShortName: "zh-CN-guangxi-YunqiNeural",
    Gender: "Male",
    Locale: "zh-CN-guangxi",
    LocaleName: "Chinese (Guangxi Accent Mandarin, Simplified)",
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN-henan, YundengNeural)",
    DisplayName: "Yundeng",
    LocalName: "云登",
    ShortName: "zh-CN-henan-YundengNeural",
    Gender: "Male",
    Locale: "zh-CN-henan",
    LocaleName: "Chinese (Zhongyuan Mandarin Henan, Simplified)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "285",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN-liaoning, XiaobeiNeural)",
    DisplayName: "Xiaobei",
    LocalName: "晓北 辽宁",
    ShortName: "zh-CN-liaoning-XiaobeiNeural",
    Gender: "Female",
    Locale: "zh-CN-liaoning",
    LocaleName: "Chinese (Northeastern Mandarin, Simplified)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
    WordsPerMinute: "229",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN-liaoning, YunbiaoNeural)",
    DisplayName: "Yunbiao",
    LocalName: "云彪 辽宁",
    ShortName: "zh-CN-liaoning-YunbiaoNeural",
    Gender: "Male",
    Locale: "zh-CN-liaoning",
    LocaleName: "Chinese (Northeastern Mandarin, Simplified)",
    SampleRateHertz: "24000",
    VoiceType: "Neural",
    Status: "Preview",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN-shaanxi, XiaoniNeural)",
    DisplayName: "Xiaoni",
    LocalName: "晓妮",
    ShortName: "zh-CN-shaanxi-XiaoniNeural",
    Gender: "Female",
    Locale: "zh-CN-shaanxi",
    LocaleName: "Chinese (Zhongyuan Mandarin Shaanxi, Simplified)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
    WordsPerMinute: "263",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN-shandong, YunxiangNeural)",
    DisplayName: "Yunxiang",
    LocalName: "云翔",
    ShortName: "zh-CN-shandong-YunxiangNeural",
    Gender: "Male",
    Locale: "zh-CN-shandong",
    LocaleName: "Chinese (Jilu Mandarin, Simplified)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "279",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-CN-sichuan, YunxiNeural)",
    DisplayName: "Yunxi",
    LocalName: "云希 四川",
    ShortName: "zh-CN-sichuan-YunxiNeural",
    Gender: "Male",
    Locale: "zh-CN-sichuan",
    LocaleName: "Chinese (Southwestern Mandarin, Simplified)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "Preview",
    WordsPerMinute: "285",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-HK, HiuMaanNeural)",
    DisplayName: "HiuMaan",
    LocalName: "曉曼",
    ShortName: "zh-HK-HiuMaanNeural",
    Gender: "Female",
    Locale: "zh-HK",
    LocaleName: "Chinese (Cantonese, Traditional)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "244",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-HK, WanLungNeural)",
    DisplayName: "WanLung",
    LocalName: "雲龍",
    ShortName: "zh-HK-WanLungNeural",
    Gender: "Male",
    Locale: "zh-HK",
    LocaleName: "Chinese (Cantonese, Traditional)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "259",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-HK, HiuGaaiNeural)",
    DisplayName: "HiuGaai",
    LocalName: "曉佳",
    ShortName: "zh-HK-HiuGaaiNeural",
    Gender: "Female",
    Locale: "zh-HK",
    LocaleName: "Chinese (Cantonese, Traditional)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "194",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-TW, HsiaoChenNeural)",
    DisplayName: "HsiaoChen",
    LocalName: "曉臻",
    ShortName: "zh-TW-HsiaoChenNeural",
    Gender: "Female",
    Locale: "zh-TW",
    LocaleName: "Chinese (Taiwanese Mandarin, Traditional)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "272",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-TW, YunJheNeural)",
    DisplayName: "YunJhe",
    LocalName: "雲哲",
    ShortName: "zh-TW-YunJheNeural",
    Gender: "Male",
    Locale: "zh-TW",
    LocaleName: "Chinese (Taiwanese Mandarin, Traditional)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "285",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zh-TW, HsiaoYuNeural)",
    DisplayName: "HsiaoYu",
    LocalName: "曉雨",
    ShortName: "zh-TW-HsiaoYuNeural",
    Gender: "Female",
    Locale: "zh-TW",
    LocaleName: "Chinese (Taiwanese Mandarin, Traditional)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "223",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zu-ZA, ThandoNeural)",
    DisplayName: "Thando",
    LocalName: "Thando",
    ShortName: "zu-ZA-ThandoNeural",
    Gender: "Female",
    Locale: "zu-ZA",
    LocaleName: "Zulu (South Africa)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "83",
  },
  {
    Name: "Microsoft Server Speech Text to Speech Voice (zu-ZA, ThembaNeural)",
    DisplayName: "Themba",
    LocalName: "Themba",
    ShortName: "zu-ZA-ThembaNeural",
    Gender: "Male",
    Locale: "zu-ZA",
    LocaleName: "Zulu (South Africa)",
    SampleRateHertz: "48000",
    VoiceType: "Neural",
    Status: "GA",
    WordsPerMinute: "90",
  },
];
