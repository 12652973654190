import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";

import App from "./pages/App";
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import Users from "./pages/Users";
import CreateUser from "./pages/CreateUser";
import Settings from "./pages/Settings";
import NotFound from "./pages/NotFound";
import ContactUs from "./pages/ContactUs";
import ProtectedRoute from "./pages/ProtectedRoute";
import { AuthProvider } from "./services/AuthService";
import { HelmetProvider } from "react-helmet-async";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<Login />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/speech" element={<App />} />
              <Route path="/users" element={<Users />} />
              <Route path="/createUser" element={<CreateUser />} />
              <Route path="/contactUs" element={<ContactUs />} />
              <Route path="/settings" element={<Settings />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>
);
