import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareWhatsapp,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import ReactGA from "react-ga4";
import NavigationBar from "../components/NavigationBar.jsx";
import { useAuth } from "../services/AuthService.jsx";
import { Helmet } from "react-helmet-async";
import axios from "axios";

const ContactUs = () => {
  const { authData } = useAuth();
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const [to, setTo] = useState("dev@smartbytes.pk");
  const [cc, setCc] = useState(authData.email);
  const [subject, setSubject] = useState([]);
  const [message, setMessage] = useState([]);

  var baseUrl =
    process.env.NODE_ENV === "development"
      ? "https://localhost:7118"
      : "https://lingoninjav2.azurewebsites.net";

  useEffect(() => {
    //Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  });

  async function handleContactSubmit(e) {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    if (!subject) {
      setError("Please add subject of email.");
      return false;
    }
    if (!message) {
      setError("Please add message of email.");
      return false;
    }

    try {
      const sendEmail = `${baseUrl}/api/User/SendEmail?Subject=${subject}&Message=${message}`;
      const response = await axios.post(sendEmail, null);
      setSuccessMessage("Email sent successful!");
      console.log(response);
      setSubject("");
      setMessage("");
    } catch (err) {
      console.error("Error in sending email: ", error);
      setError(error.response.data || "An error occurred while sending email.");
    }
  }

  function handleSubjectChange(e) {
    setSubject(e.target.value);
  }

  function handleMessageChange(e) {
    setMessage(e.target.value);
  }

  function handleToChange(e) {
    setTo(e.target.value);
  }

  function handleCCChange(e) {
    setCc(e.target.value);
  }

  return (
    <Container className="contact-us-container">
      <Helmet>
        <title>Contact Us | LingoNinja</title>
        <meta
          name="description"
          content="Contact our Support Team through WhatsApp, Telegram or Email"
        />
        <link rel="canonical" href="/ContactUs" />
      </Helmet>
      <NavigationBar />
      <div className="">
        <div className="row">
          <div className="col-sm-6 brands-container col-12 mt-3 mb-3 text-center">
            <div className="card">
              <div className="card-body">
                <h1 className="text-dark">
                  Contact our Support Team through WhatsApp, Telegram or Email
                </h1>
                <div className="row">
                  <div className="col-md-6 col-6">
                    <a
                      href="https://wa.me/923174126711?text=Hi Lingo Ninja Support!"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faSquareWhatsapp}
                        className="fa-10x m-2"
                      />
                      <h3>WhatsApp</h3>
                    </a>
                  </div>
                  <div className="col-md-6 col-6">
                    <a
                      href="https://t.me/SmartBytesPK"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faTelegram}
                        className="fa-10x m-2"
                      />
                      <h3>Telegram</h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <form onSubmit={handleContactSubmit}>
              <div className="card">
                <div className="card-body shadow">
                  <h3>Email</h3>
                  {successMessage && successMessage.length > 0 && (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  )}
                  {error && error.length && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      value={to}
                      disabled
                      onKeyUp={handleToChange}
                      onChange={handleToChange}
                    />
                    <label htmlFor="floatingInput">To</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      value={cc}
                      disabled
                      onKeyUp={handleCCChange}
                      onChange={handleCCChange}
                    />
                    <label htmlFor="floatingInput">CC</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      value={subject}
                      onKeyUp={handleSubjectChange}
                      onChange={handleSubjectChange}
                    />
                    <label htmlFor="floatingInput">Subject</label>
                  </div>
                  <div className="form-floating mb-3">
                    <textarea
                      className="form-control"
                      id="floatingMessage"
                      value={message}
                      onKeyUp={handleMessageChange}
                      onChange={handleMessageChange}
                      placeholder="Message"
                      style={{ height: "100px" }}
                    />
                    <label htmlFor="floatingMessage">Message</label>
                  </div>
                  <button className="btn btn-primary w-100 py-2" type="submit">
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ContactUs;
