import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import ReactGA from "react-ga4";
import NavigationBar from "../components/NavigationBar.jsx";
import { Helmet } from "react-helmet-async";
import { useAuth } from "../services/AuthService.jsx";
import axios from "axios";

const Settings = () => {
  const { authData, updateAuthData } = useAuth();
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const [name, setName] = useState(authData.name);
  const [email, setEmail] = useState(authData.email);
  const [currentPassword, setCurrentPassword] = useState("");
  const [updatedPassword, setUpdatedPassword] = useState("");
  const [confirmUpdatedPassword, setConfirmUpdatedPassword] = useState("");

  useEffect(() => {
    //Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  });

  var baseUrl =
    process.env.NODE_ENV === "development"
      ? "https://localhost:7118"
      : "https://lingoninjav2.azurewebsites.net";

  const handleSettingsSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    if (!currentPassword) {
      setError("Please enter into current password field.");
      return false;
    }
    if (!updatedPassword) {
      setError("Please enter into updated password field.");
      return false;
    }
    if (!confirmUpdatedPassword) {
      setError("Please enter into confirm updated password field.");
      return false;
    }
    if (updatedPassword !== confirmUpdatedPassword) {
      setError(
        "Please enter same updated password and confirm updated password."
      );
      return false;
    }

    try {
      // Perform the API call to update the user
      const updatePasswordUrl = `${baseUrl}/api/User/ChangePassword`;
      const params = {
        CurrentPassword: currentPassword,
        NewPassword: updatedPassword,
      };

      console.log(params);

      const response = await axios.post(updatePasswordUrl, null, { params });

      setSuccessMessage("Password updated successfully!");

      console.log(response);

      // Close the modal after successful submission
    } catch (error) {
      console.error("Error updating user: ", error);
      setError(
        error.response.data || "An error occurred while updating password."
      );
      // Handle error, display error message, etc.
    }
  };

  function handleNameChange(e) {
    setName(e.target.value);
  }

  function handleCurrentPasswordChange(e) {
    setCurrentPassword(e.target.value);
  }

  function handleUpdatedPasswordChange(e) {
    setUpdatedPassword(e.target.value);
  }

  function handleConfirmUpdatedPasswordChange(e) {
    setConfirmUpdatedPassword(e.target.value);
  }

  function handleEmailChange(e) {
    setEmail(e.target.value);
  }

  return (
    <Container className="settings-container">
      <Helmet>
        <title>Settings | LingoNinja</title>
        <meta name="description" content="Update account settings" />
        <link rel="canonical" href="/Settings" />
      </Helmet>
      <NavigationBar />
      <div className="">
        <div className="form-signin w-100 m-auto text-center">
          <div className="">
            <form onSubmit={handleSettingsSubmit}>
              <div className="card">
                <div className="card-body shadow">
                  <h3>Settings</h3>
                  {successMessage && successMessage.length > 0 && (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  )}
                  {error && error.length && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={name}
                      onKeyUp={handleNameChange}
                      onChange={handleNameChange}
                      placeholder="Name"
                    />
                    <label htmlFor="floatingInput">Name</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="password"
                      className="form-control"
                      value={currentPassword}
                      onKeyUp={handleCurrentPasswordChange}
                      onChange={handleCurrentPasswordChange}
                      placeholder="Current Password"
                    />
                    <label htmlFor="floatingInput">Current Password</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="password"
                      className="form-control"
                      value={updatedPassword}
                      onKeyUp={handleUpdatedPasswordChange}
                      onChange={handleUpdatedPasswordChange}
                      placeholder="Update Password"
                    />
                    <label htmlFor="floatingInput">Updated Password</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="password"
                      className="form-control"
                      value={confirmUpdatedPassword}
                      onKeyUp={handleConfirmUpdatedPasswordChange}
                      onChange={handleConfirmUpdatedPasswordChange}
                      placeholder="Confirm Updated Password"
                    />
                    <label htmlFor="floatingInput">
                      Confirm Updated Password
                    </label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingEmail"
                      disabled
                      value={email}
                      onKeyUp={handleEmailChange}
                      onChange={handleEmailChange}
                      placeholder="Email"
                    />
                    <label htmlFor="floatingEmail">Email</label>
                  </div>
                  <button className="btn btn-primary w-100 py-2" type="submit">
                    Update Settings
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Settings;
